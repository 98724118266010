import {MutationUpdateOrganizationArgs, Organization} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "@organizations/screens/edit/OrganizationForm";
import {Await, useActionData, useLocation, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {useOrganizationDetailsLoader} from "@organizations/loaders/organizationDetailsLoader";

export const OrganizationUpdate = () => {
    const submit = useSubmit()
    const {organization} = useOrganizationDetailsLoader()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const location = useLocation()
    const navigate = useNavigate()
    const loading = navigation.state === "loading"

    const _normalize = (organization: Organization): MutationUpdateOrganizationArgs["organization"] => {
        const address = organization.address as Required<any>
        return {
            hid: organization.hid as string,
            name: organization.name,
            shortName: organization.shortName,
            leaderLastName: organization.leaderLastName,
            leaderFirstName: organization.leaderFirstName,
            email: organization.email,
            phoneNumber: organization.phoneNumber,
            website: organization.website,
            address: {
                street: address.street,
                city: address.city,
                province: address.province,
                postalCode: address.postalCode,
                country: address.country,
                displayName: address.displayName,
                visibility: address.visibility ?? "public"
            },
        }
    }

    const handleSubmit = (organization: MutationUpdateOrganizationArgs["organization"]) => {
        submit(organization, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(location.state?.hasOwnProperty("from") ? location.state.from : "..")

    }

    console.log("RETURN", validationErrors)

    return <Await resolve={organization}>
        {({data}) =>
            <FullScreenDialog title={`Mettre à jour ${data.name}`} onClose={handleCancel}>
                <OrganizationForm<MutationUpdateOrganizationArgs["organization"]>
                    organization={_normalize(data)}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    loading={loading}
                    errors={validationErrors}
                />
            </FullScreenDialog>}
    </Await>
}