import React, {useEffect} from 'react';
import './App.css';
import MainRouter from "./router/MainRouter";
import {ConnectedUserProvider} from "@profusion.media/profusion-iam-user";
import {useAppReady} from "@profusion.media/profusion-core";
import {DrawerMenuProvider, WaitingPage} from "@profusion.media/profusion-ui";
import {SafeArea} from 'capacitor-plugin-safe-area';
import {StatusBar} from '@capacitor/status-bar';
import {PushNotificationProvider} from "./components/NotificationCenter/PushNotificationProvider";
import AppUrlListener from "./router/AppUrlListener";
import {WaitingScreen} from "@components/WaitingScreen";

export const App = () => {
    const appReady = useAppReady()
    const navigate = (action: string) => {
        window.location.href = action;
    }

    useEffect(() => {
        (async function () {
            const safeAreaData = await SafeArea.getSafeAreaInsets();
            const {insets} = safeAreaData;
            for (const [key, value] of Object.entries(insets)) {
                document.documentElement.style.setProperty(
                    `--safe-area-inset-${key}`,
                    `${value}px`,
                );
            }
            await StatusBar.show();
            await StatusBar.setBackgroundColor({
                color: "#1976d2"
            })
        })()
    }, []);

    if (!appReady) {
        return <WaitingPage show={true}/>
    }

    const onPushAction = (action: string) => {
        navigate(action)
    }

    const onSignOut = () => {
        navigate("/");
    }

    return <DrawerMenuProvider>
        <ConnectedUserProvider onSignOut={onSignOut} waitElement={<WaitingPage show={true} />}>
            <PushNotificationProvider onAction={onPushAction}>
                <MainRouter/>
            </PushNotificationProvider>
        </ConnectedUserProvider>
    </DrawerMenuProvider>


}

export default App;
