import {InvitationOptionProps} from "./typings";
import {WhiteBox} from "@components/WhiteBox";
import UserSearch from "@components/UserSearch/UserSearch";
import {SearchResult} from "@profusion.media/graph";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {Button, Stack} from "@mui/material";
import {useActionData, useNavigation, useSubmit} from "react-router-dom";
import {CommunityInviteResponse} from "@graph";
import {LoadingButton} from "@mui/lab";

export const PeopleSearchOption = (props: InvitationOptionProps) => {
    const {url, group} = props
    const submit = useSubmit()
    const data = useActionData() as CommunityInviteResponse
    const {state} = useNavigation()
    const [selected, setSelected] = useState<SearchResult | null>(null);

    const handleSelect = (v: SearchResult | null) => {
        setSelected(v)
    }

    const handleClear = () => {
        setSelected(null)
    }

    const handleSubmit = () => {
        if (selected) {
            submit({
                groupHid: group.hid,
                userHid: selected.hid,
                isHost: false,
                isActive: true,
            }, {
                method: "POST",
                encType: "application/json",
            })
        }
    }


    return <>
        <UserSearch onChange={handleSelect}/>
        {selected && <>
            {!data && <WhiteBox title="Important">
                <Stack spacing={4}>
                    <Typography variant="body2">{selected.displayName} aura accès à toutes les fonctionnalités
                        et contenus de ce groupe</Typography>
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        loading={state === "loading"}>Confirmer</LoadingButton>
                </Stack>
            </WhiteBox>}
            {data && data.success && <WhiteBox title="Invitation envoyée !">
                <Stack spacing={4}>
                    <Typography variant="body2">{selected.displayName} apparaîtra dans la liste quand l'invitation
                        aura été acceptée.
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={handleClear}
                        variant="contained">Inviter d'autres personnes</Button>
                </Stack>
            </WhiteBox>}
            {data && !data.success && <WhiteBox title="Une erreur est survenue !">
                <Stack spacing={4}>
                    <Typography variant="body2">L'invitation n'a pas pu être envoyée à {selected.displayName} pour le
                        moment.
                    </Typography>
                    <Button
                        color="secondary"
                        onClick={handleClear}
                        variant="contained">Inviter d'autres personnes</Button>
                </Stack>
            </WhiteBox>}
        </>
        }
    </>

}