import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import {useNavigate} from "react-router-dom";
import {ImportContactsOutlined} from "@mui/icons-material";


export const SuperAdminSpeedDial = () => {
    const navigate = useNavigate()

    const actions = [
        {icon: <FileCopyIcon/>, name: 'Copy', onClick: () => navigate("/su/organizations/add")},
        {icon: <ImportContactsOutlined/>, name: 'PDF', onClick: () => navigate("/library/test")},

    ];

    return (
        <Box sx={{height: 320, transform: 'translateZ(0px)', flexGrow: 1}}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{position: 'absolute', bottom: 16, right: 16}}
                icon={<SpeedDialIcon/>}

            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}