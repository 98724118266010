import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {communityMembershipQuery} from "@community/loaders/communityMembershipLoader";
import React, {useEffect, useState} from "react";
import profusion from "@config/profusion";
import {CommunityMembership} from "@graph";
import {Box, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import {
    DrawerCommunityInactiveMemberMenu
} from "@components/layout/dashboard/drawer/community/DrawerCommunityInactiveMemberMenu";
import {DrawerCommunityAdminMenu} from "@components/layout/dashboard/drawer/community/DrawerCommunityAdminMenu";
import {UserAvatar} from "@profusion.media/profusion-ui";


export const DrawerCommunityMembershipMenu = () => {
    const user = useConnectedUser()
    const membershipQuery = communityMembershipQuery(profusion)
    const [membership, setMembership] = useState<CommunityMembership[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        membershipQuery.then(result => {
            setMembership(result.data || [])
        })
    }, [membershipQuery])

    if (membership.length === 0) return


    return membership.map((member: CommunityMembership) => (member.state == 'A_1' || member.isAdmin) &&
        <Box sx={{
            backgroundColor: "#eaf4ff",
            borderTop: "2px #ddd solid"
        }}>
            <List
                subheader={<ListSubheader sx={{
                    backgroundColor: "inherit",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1
                }}>{member.communityLogo && <UserAvatar size={24} avatar={member.communityLogo.smallUrl} />}
                    {member.communityName}
                </ListSubheader>}>

                {member.state == 'A_1' && <DrawerCommunityInactiveMemberMenu membership={member}/>}

                {member.isAdmin && <DrawerCommunityAdminMenu membership={member} />}
            </List>
        </Box>
    )
}