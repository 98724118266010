import {CommunityMembership} from "@graph";
import {useNavigate} from "react-router-dom";
import {ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {faPrayingHands} from "@fortawesome/pro-light-svg-icons";

interface DrawerCommunityAdminMenuProps {
    membership: CommunityMembership;
}

export const DrawerCommunityAdminMenu = (props: DrawerCommunityAdminMenuProps) => {
    const {membership} = props;
    const navigate = useNavigate()

    const handleGoAdmin = (hid: string) => {
        navigate(`/community/admin/${hid}/members`)
    }

    const handleGoAdminSettings = (hid: string) => {
        navigate(`/community/admin/${hid}/settings`)
    }

    const handleGoPrayer = () => {
        navigate(`/groups/${membership.prayerGroupHid}/prayer`)
    }

    return <>
        <ListItem onClick={_ => handleGoAdmin(membership.communityHid)}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faGear} fixedWidth/>
            </ListItemIcon>
            <ListItemText primary={"Gérer les membres et groupes"}/>
        </ListItem>
        {membership.prayerGroupHid && <ListItem onClick={_ => handleGoPrayer()}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faPrayingHands} fixedWidth/>
            </ListItemIcon>
            <ListItemText primary={"Prière"}/>
        </ListItem>}
        <ListItem onClick={_ => handleGoAdminSettings(membership.communityHid)}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faGear} fixedWidth/>
            </ListItemIcon>
            <ListItemText primary={"Paramètres"}/>
        </ListItem>
    </>
}