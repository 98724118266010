import {gql} from "@profusion.media/profusion-core";

export const ORGANIZATION_FIELDS = gql`
    fragment organizationFields on Organization {
        hid
        parentHid
        name
        shortName
        leaderFirstName
        leaderLastName
        language
        address {
            city
            coordinates
            country
            postalCode
            province
            street
        }
        mainCommunity {
            hid
            isMain
        }
        email
        phoneNumber
        website
        profile {
            displayName
            avatar {
                smallUrl
                largeUrl
                mediumUrl
            }
        }
    }
    `