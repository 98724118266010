import {Await, Outlet, useParams, useRouteLoaderData} from "react-router-dom";
import {Box, Card, CardHeader, Container, Stack} from "@mui/material";
import {LoadDetailsReturn} from "./loaders";
import {Suspense, useEffect} from "react";
import {GroupTabs} from "./components/GroupTabs";
import {GroupAvatar} from "./components/GroupAvatar";
import {useTranslation} from "react-i18next";
import {useNavigateDetailsTab} from "../hooks/navigation";
import {useTabIndex} from "./hooks";
import {Group} from "@profusion.media/profusion-core-group"
import {GroupDetailsSubHeader} from "./components/GroupDetailsSubHeader";
import {GroupDetailsPrivacyInfoHeader} from "./components/GroupDetailsPrivacyInfoHeader";
import {GroupSpeedDial} from "./components/GroupSpeedDial/GroupSpeedDial";
import {BodyContainer} from "@components/BodyContainer";
import Typography from "@mui/material/Typography";
import {TagChipGroup} from "@community/components/TagChip";
import {CommunityTag} from "@graph";

export const GroupDetails = () => {
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn
    const {t} = useTranslation()
    const {hid} = useParams()
    const navigateTab = useNavigateDetailsTab(hid || "")



    const RedirectFirstTab = (props: {g: Group} ) => {

        const tabIndex = useTabIndex()
        const {g} = props
        useEffect(() => {
            console.log(g, tabIndex)
            if (!tabIndex) {
                if (g.mainFeature === 'prayer') {
                    navigateTab("prayer")
                } else {
                    navigateTab("content")
                }
            }
        }, []);
        return <></>
    }

    return <Suspense>
        <Await resolve={groupData}>
            {({data: group}: { data: Group }) => <>
                <RedirectFirstTab g={group} />
                <Card>
                    <CardHeader title={group.title}
                                avatar={<Stack direction="row" spacing={1} alignItems="center">
                                    <GroupAvatar group={group} size={48}/>
                                </Stack>}
                                titleTypographyProps={{variant: "h5"}}
                                subheader={<Box>
                                    {group.tags && <TagChipGroup tags={group.tags as CommunityTag[]} />}
                                    {group.createdBy && <Typography variant={"body2"}>Groupe de {group.createdBy.displayName}</Typography>}
                                    {group.organizationName && <Typography variant={"body2"}>Groupe de {group.organizationName}</Typography>}
                                </Box>}
                    />
                    <GroupDetailsPrivacyInfoHeader group={group}/>
                    <GroupDetailsSubHeader group={group}/>

                    <GroupTabs group={group}/>
                </Card>
                <BodyContainer>
                    <Outlet/>
                </BodyContainer>
                <GroupSpeedDial group={group} />
            </>
            }
        </Await>
    </Suspense>
}