import {useOrganizationDetails} from "@organizations/loaders/organizationDetailsLoader";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";
import {Community} from "@graph";

export interface CommunityOrganizationInfoProps {
    community: Community
}

export const CommunityOrganizationInfo = (props: CommunityOrganizationInfoProps) => {
    const {community} = props
    const getDetails = useOrganizationDetails()
    const [data, setData] = React.useState<any>()

    useEffect(() => {
        const f = async () =>
        {
            const {data: org} = await getDetails(community?.organization?.hid ?? "")
            setData(org)
        }
        f()
    }, [community])

    if (!data) return null

    return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
        <UserAvatar size={64} avatar={data.profile?.avatar?.smallUrl} userName={data.profile?.displayName}/>

        <Typography variant="h6" fontWeight={700} color="secondary">{community.name}</Typography>

        <Typography variant="body1">{data.name}</Typography>
        <Typography variant="body2">{data.address.city}</Typography>
    </Box>
}