import {Await, Outlet, useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";
import {AppBar, Container, CssBaseline, IconButton, Toolbar} from "@mui/material";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Suspense, useEffect, useState} from "react";
import {LoadDetailsReturn} from "../../GroupDetails/loaders";
import {GroupAppBarSettings} from "./GroupAppBarSettings";
import Typography from "@mui/material/Typography";
import {GroupAppBarShare} from "./GroupAppBarShare";
import {BodyContainer} from "@components/BodyContainer";
import {ScreenContainer} from "@components/ScreenContainer";

export const GroupScreen = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [previousUrl, setPreviousUrl] = useState("..")
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn

    useEffect(() => {
        setPreviousUrl(location.state?.from || "..")
    }, []);

    const handleBack = () => {
        navigate(previousUrl)
    }


    return <Suspense>
        <Await resolve={groupData}>
            {({data: group}) =>
                <ScreenContainer>
                    <AppBar position="fixed">
                        <Container maxWidth="lg">
                            <Toolbar disableGutters>
                                <IconButton onClick={handleBack}>
                                    <FontAwesomeIcon color="#f0f0f0" icon={faArrowLeft}/>
                                </IconButton>
                                <Typography flexGrow={1}></Typography>
                                <GroupAppBarShare group={group}/>
                                <GroupAppBarSettings group={group}/>

                            </Toolbar>
                        </Container>
                    </AppBar>
                <BodyContainer maxWidth="lg" sx={{mt: 8}} disableGutters>
                    <Outlet/>
                </BodyContainer>
                </ScreenContainer>
            }
        </Await>
    </Suspense>
}