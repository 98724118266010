import profusion from "@config/profusion"
import {ActionFunctionArgs} from "react-router-dom"
import {executeMutation, MutationInput} from "@profusion.media/profusion-core";

import {
    MutationCreateOrganizationArgs,
    OrganizationUpdateInput,
    Organization,
    OrganizationCreate,
    OrganizationUpdate
} from "@profusion.media/graph";
import {ORGANIZATION_CREATE_MUTATION} from "@organizations/schema/mutations/organization_create_mutation";
import {ORGANIZATION_UPDATE_MUTATION} from "@organizations/schema/mutations/organization_update_mutation";

export type onOrganizationEditSuccessHandler = (organization: Organization) => void;

export const organizationCreate = (onSuccess: onOrganizationEditSuccessHandler) => async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as MutationCreateOrganizationArgs

    const response = await executeMutation<OrganizationCreate, MutationCreateOrganizationArgs>(
        profusion,
        ORGANIZATION_CREATE_MUTATION,
        data, {
            isPrivate: true,
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return onSuccess(response?.data?.organization as Organization)
}

export const organizationUpdate = (onSuccess: onOrganizationEditSuccessHandler) => async (props: ActionFunctionArgs) => {
    const data = await props.request.json() as OrganizationUpdateInput
    console.log(data)
    const response = await executeMutation<OrganizationUpdate,{organization:OrganizationUpdateInput}>(
        profusion,
        ORGANIZATION_UPDATE_MUTATION,{
            organization: data
        }, {
            isPrivate: true
        })
    if (response.validationErrors) {
        return response.validationErrors
    }
    return onSuccess(response?.data?.organization as Organization)
}