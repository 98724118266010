import {useState} from "react";
import {MutationCreateOrganizationArgs, SearchOrganizationResult} from "@profusion.media/graph";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {OrganizationForm} from "@organizations/screens/edit/OrganizationForm";
import {emptyOrganizationInput} from "../../typings";
import {useActionData, useNavigate, useNavigation, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {OrganizationTypeChooser} from "./OrganizationTypeChooser";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import SearchOrganizationInput from "./components/OrganizationSeachInput/OrganizationSearchInput";
import {SearchOptionValue} from "./components/OrganizationSeachInput/SearchOptionValue";
import List from "@mui/material/List";

export const OrganizationCreate = () => {
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const [data, setData] = useState<MutationCreateOrganizationArgs>({
        organization: {...emptyOrganizationInput,
            address: {
                country: "CA",
                visibility: "visible",
                displayName: "",
                province: "CA_QC",
                street: "",
                postalCode: "",
                city: ""
            }
        },
        parent: ""
    });
    const [selectParent, setSelectParent] = useState<boolean>(true)
    const [parentData, setParentData] = useState<SearchOrganizationResult>()
    const navigate = useNavigate()
    const loading = navigation.state === "loading"

    const handleSubmit = async (organization: MutationCreateOrganizationArgs["organization"]) => {
        const newData = {...data, organization}
        setData(newData)
        submit(newData, {
            method: "post",
            encType: "application/json"
        })
    }

    const setType = (v: string) => {
        const organization = {...data.organization, type: v}
        setData(prev => ({...prev, organization}))
        setSelectParent(['church', 'partner'].includes(v))
    }

    const setParent = (v: SearchOrganizationResult|undefined) => {
        console.log("set parent "+v)
        setParentData(v)
        setData(prev => ({...prev, parent: v?.hid || ""}))
    }

    const handleCancel = () => {
        navigate("/su/organizations")
    }

    return <FullScreenDialog title="Ajouter une organisation" onClose={handleCancel}>
        <OrganizationTypeChooser value={data.organization.type || "church"} onChange={setType}/>
        {selectParent && <Box padding={2} marginBottom={1} bgcolor="#fff">
            <Typography variant="overline">Organisme parent</Typography>
            {data.parent === "" && <SearchOrganizationInput onChange={setParent} />}
            {parentData && <List><SearchOptionValue value={parentData} onClear={() => setParent(undefined)} showClear={true} /></List>}
        </Box> }
        <OrganizationForm<MutationCreateOrganizationArgs["organization"]>
            organization={data.organization}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            loading={loading}
            errors={validationErrors}
            type={data.organization.type || "church"}
        />
    </FullScreenDialog>
}