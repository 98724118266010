import {Outlet} from "react-router-dom"

import ErrorPage from "../errors/ErrorPage";
import {PersonalInfoRoutes} from "../PersonalInfo/PersonalInfoRouter";
import {DeleteAccount} from "../delete/DeleteAccount";
import DashboardLayout from "@components/layout/dashboard/DashboardLayout";


const AccountLayout = () => {
    return <DashboardLayout title="Mon compte">
        <Outlet/>
    </DashboardLayout>;
}


const AccountRouter = [
    {
        path: "",
        element: <AccountLayout/>,
        errorElement: <ErrorPage/>,
        children: [
            ...PersonalInfoRoutes,
            {
                path: "delete",
                element: <DeleteAccount/>,
            }
        ]
    }
]

export default AccountRouter