import {StepComponentProps} from "./StepsProps";

import {
    FieldErrorBox,
    NextStepButton,
    PasswordInput,
    PhoneInput,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui";
import React, {useState} from "react";
import {Box, FormControlLabel, Link, Stack, Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import {WhiteBox} from "@components/WhiteBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons";

export const StepIdentity = (props: StepComponentProps) => {
    const {value, onChange} = props
    const {t} = useTranslation()
    const [haveCode, setHaveCode] = useState<boolean>(false)
    const [cgu, setCGU] = useState(false)
    const [age13, setAge13] = useState(false)

    const handleCheckAge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAge13(event.target.checked);
    };
    const handleCheckCGU = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCGU(event.target.checked);
    };
    const handleCheckHaveCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHaveCode(event.target.checked);
    };
    return <Stack spacing={4}>
        <TextInput value={value.firstName}
                   name="firstName"
                   required
                   label={t("account.first_name", "Prénom")}
                   onValueChange={onChange}/>

        <TextInput value={value.lastName}
                   name="lastName"
                   required
                   label={t("account.last_name", "Nom")}
                   onValueChange={onChange}/>

        <TextInput value={value.email}
                   name="email"
                   type="email"
                   required
                   label={t("account.email", "E-mail")}
                   onValueChange={onChange}/>

        <FieldErrorBox field="email" errorCode="DuplicateValue">
            {t("account.email_exists", "Un compte existe déjà avec cette adresse")}
        </FieldErrorBox>

        <PhoneInput value={value.phoneNumber ?? ""}
                    name="phoneNumber"
                    label={t("account.phoneNumber", "Numéro de cellulaire")}
                    onValueChange={onChange}/>

        <PasswordInput onValueChange={onChange}
                       onChange={_ => ""}
                       value={value.password}
                       name="password"
                       required
                       label={t("account.password", "Mot de passe")}
        />
        <FormControlLabel
            required
            control={<Switch checked={haveCode}
                             onChange={handleCheckHaveCode}/>}
            label={t("account.check.haveCode", "J'ai reçu un code d'invitation")}/>

        {haveCode && <Box >
            <TextInput value={value.invitationCode}
                       name="invitationCode"
                       label={t("account.invitation_code", "Code d'invitation")}
                       onValueChange={onChange}/>
        </Box>}


        <FormControlLabel
            required
            control={<Switch checked={age13}
                             onChange={handleCheckAge}/>}
            label={t("account.check.age", "Je certifie avoir 13 ans ou plus")}/>


        <FormControlLabel
            required
            control={<Switch checked={cgu} onChange={handleCheckCGU}/>}
            label={t("account.check.cgu", "j'ai pris connaissance de la politique de confidentialité")}/>

        <Link href="https://profusion.media/politique-de-confidentialite"
              target="_blank"
              mt={1}
        >
            <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth={true}/>
            {t("account.link.cgu", "lire la politique de confidentialité")}
        </Link>

        <SubmitButton
            disabled={!age13 || !cgu}
            label={t("register.steps.finish", "Créer le compte")}/>
    </Stack>

}