import {ActionFunctionArgs, redirect} from "react-router-dom"
import {Organization,} from "@profusion.media/graph";
import {organizationCreate, organizationUpdate} from "@organizations/actions/organization_edit";

export const actionOrganizationCreate = async (props: ActionFunctionArgs) => {
    return organizationCreate((organization: Organization) => {
        return redirect(`/su/organizations/${organization.hid}`)
    })

}
export const actionOrganizationUpdate = async (props: ActionFunctionArgs) => {
    return organizationUpdate((organization: Organization) => {
        return redirect(`/su/organizations/${organization.hid}`)
    })

}