import {Group} from "@graph";
import {WhiteBox} from "@components/WhiteBox";
import {Box, Button} from "@mui/material";
import {useNavigateInvite} from "@groups/Groups/hooks/navigation";
import AddIcon from "@mui/icons-material/Add";

export interface GroupSubscriberEmptyProps {
    group: Group
}

export const GroupSubscriberEmpty = (props: GroupSubscriberEmptyProps) => {
    const {group} = props
    const invite = useNavigateInvite()

    console.log(group)

    return <WhiteBox title={"Aucun participant"}>
        <Box padding={4} textAlign="center">
            <Button
            variant="contained"
            startIcon={<AddIcon />}
                onClick={_ => invite(group.hid)}>Inviter</Button>
        </Box>
    </WhiteBox>
}