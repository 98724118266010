import React from "react"
import {Avatar, Box, Button, ButtonGroup} from "@mui/material";
import {ImageUploaderDialog, UploadedImage} from "../ImageUploader";
import {AvatarEditorLabels, AvatarEditorProps} from "./typings";

const defaultLabels: AvatarEditorLabels = {
    addButton: "Ajouter",
    modifyButton: "Modifier",
    deleteButton: "Supprimer",
    dialogTitle: "Choisir une image"
}

const defaultProps: Partial<AvatarEditorProps> = {
    folder: "users",
    shape: "rounded",
    prefix: "usr",
    labels: defaultLabels,
    defaultImage: "https://ik.imagekit.io/profusion/Static_files/877592470d87109eeb659aa91e750039.jpg?updatedAt=1684865194773"
}

export const AvatarEditor = (props: AvatarEditorProps) => {
    const {image, shape, folder, prefix, onChange, onDelete, defaultImage, labels} = {...defaultProps, ...props}
    const [openDialog, setOpenDialog] = React.useState<boolean>(false)

    const hasImage = typeof image !== "undefined" && image !== "" && image !== defaultImage

    console.log("AVATAR", defaultImage, image)
    const handleModify = () => {
        setOpenDialog(true)
    }

    const handleDelete = () => {
        onDelete()
    }

    const handleUploaded = (uploaded: UploadedImage) => {
        onChange(uploaded)
        setOpenDialog(false)
    }

    function closeDialog() {
        setOpenDialog(false)
    }

    return <Box>
        <Box sx={{float: "left"}}>
            <Avatar sx={{width: 64, height: 64}}
                    src={hasImage ? image : defaultImage} alt="avatar">
            </Avatar>
        </Box>
        <ButtonGroup orientation="vertical" variant="text" sx={{ml: 3}}>
            <Button
                color="primary"
                fullWidth={false}
                onClick={_ => handleModify()}>
                {image ? labels.modifyButton : labels.addButton}
            </Button>
            {hasImage &&
                <Button
                    color="error"
                    onClick={_ => handleDelete()}>{labels.deleteButton}</Button>
            }
        </ButtonGroup>
        <ImageUploaderDialog open={openDialog}
                             onUploaded={handleUploaded}
                             onCancel={closeDialog}
                             image={image}
                             folder={folder}
                             shape={shape}
                             title={labels.dialogTitle}
                             filePrefix={prefix}
        />
    </Box>
}