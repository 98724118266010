import {InvitationOptionProps} from "./typings";
import {WhiteBox} from "@components/WhiteBox";
import {Button, Stack, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faShareAlt} from "@fortawesome/pro-regular-svg-icons";
import {faQrcode} from "@fortawesome/pro-light-svg-icons";
import {Share} from "@capacitor/share"
import {useNavigate} from "react-router-dom";
import {ClipboardCopyButton} from "@components/ClipboardCopyButton";

export const ShareLinkOption = (props: InvitationOptionProps) => {
    const {url, group} = props
    const navigate = useNavigate()

    const onShare = () => {
        Share.share({
            url: url,
            text: "Répondez à l'invitation :"
        })
    }
    const onQR = () => {
        navigate("../qr")
    }

    return <WhiteBox title="Lien de partage" icon={<FontAwesomeIcon icon={faLink}/>}>
        <TextField value={url}
                   variant="outlined"
                   fullWidth
                   margin="dense"
                   InputProps={{
                       endAdornment: <ClipboardCopyButton value={{url}}/>
                   }}
        />
        <Stack direction="row" mt={2} spacing={2}>
            <Button
                variant="outlined"
                onClick={onQR}
                startIcon={<FontAwesomeIcon icon={faQrcode}/>}
            >Code QR</Button>
            <Button
                variant="outlined"
                onClick={onShare}
                startIcon={<FontAwesomeIcon icon={faShareAlt}/>}
            >Partager</Button>
        </Stack>
    </WhiteBox>
}