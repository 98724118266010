import {ActionFunctionArgs, redirect} from "react-router-dom"
import {Organization,} from "@profusion.media/graph";
import {organizationCreate, organizationUpdate} from "@organizations/actions/organization_edit";

export const actionCommunityOrganizationCreate = () => {
    return organizationCreate((organization: Organization) => {
        return redirect(`/community/admin/${organization.mainCommunity?.hid}/members`)
    })

}
export const actionCommunityOrganizationUpdate = () => {
    return organizationUpdate((organization: Organization) => {
        return redirect(`/community/admin/${organization.mainCommunity?.hid}/members`)
    })

}