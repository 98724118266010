import {Await, useNavigate, useParams} from "react-router-dom";
import {LoadOrganizationListParams, useOrganizationListLoader} from "../../loaders/organizationListLoader";
import {Suspense} from "react";
import {Paper} from "@mui/material";
import {Organization} from "@profusion.media/graph";
import Typography from "@mui/material/Typography";
import {OrganizationListTabs} from "./OrganizationListTabs";

export const OrganizationList = () => {
    const {page, nb} = useParams<LoadOrganizationListParams>()
    const {organizations} = useOrganizationListLoader()
    const navigate = useNavigate()

    const handleDetails = (organization: Organization) => {
        navigate(`/su/organizations/${organization.hid}`)
    }

    return <Suspense>
        <Paper sx={{p: 2}}>
            <Typography variant="h6">Organisations</Typography>
        </Paper>
        <Await resolve={organizations}>
            {({data}) => <OrganizationListTabs organizations={data} onSelect={handleDetails}/>}
        </Await>
    </Suspense>
}