import {useUserAvailableContentLoader} from "@community/loaders/userAvailableContent";
import {Await, useNavigate} from "react-router-dom";
import {Badge, ListItem, ListItemIcon} from "@mui/material";
import {faBooks} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export const UserContentMenu = () => {
    const {content} = useUserAvailableContentLoader("appRoot")
    const navigate = useNavigate();

    return <Await resolve={content}>
        {({data: content}) => content && <ListItem onClick={_ => navigate("/content")}>
            <ListItemIcon>
                <FontAwesomeIcon icon={faBooks}/>
            </ListItemIcon>
            <ListItemText primary={<Badge color="secondary" badgeContent={content.length}><Typography sx={{pr:2}}>Mes contenus</Typography></Badge>}
            />
        </ListItem>}
    </Await>
}