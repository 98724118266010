import {TabContext, TabList} from "@mui/lab";
import React, {Suspense, useState} from "react";
import {Box, Tab} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import {CommunityGroupList} from "@community/screens/groups/list/CommunityGroupList";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Await} from "react-router-dom";
import {Group} from "@graph";
import {WhiteBox} from "@components/WhiteBox";


export const CommunityGroupListTabs = () => {
    const {groups} = useCommunityDetailsLoader("communityDetailsAdmin")

    const [activeTab, setActiveTab] = useState<string>("share")
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };
    return <Suspense>
        <Await resolve={groups}>
            {({data}) =>
                <WhiteBox padding={0}>
                <TabContext value={activeTab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChangeTab}>
                            <Tab value={"share"} label="Groupes"/>
                            <Tab value={"prayer"} label="Prière"/>
                        </TabList>
                    </Box>
                    <TabPanel sx={{p:0}} value={"prayer"}>
                        <CommunityGroupList groups={data.filter((g: Group) => g.mainFeature == 'prayer')}/>
                    </TabPanel>
                    <TabPanel sx={{p:0}} value={"share"}>
                        <CommunityGroupList groups={data.filter((g: Group) => g.mainFeature == 'share')}/>
                    </TabPanel>
                </TabContext>
                </WhiteBox>
            }
        </Await>
    </Suspense>
}