import * as React from 'react';
import {startTransition} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from "react-router-dom";
import {
    faBookmark,
    faHome,
    faPlusCircle,
    faRectangleHistoryCircleUser,
    faShield,
    faUserGroup
} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {DrawerCommunityMembershipMenu} from "@components/layout/dashboard/drawer/community/DrawerCommunityMembershipMenu";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import {Box, Button, ListItem, ListItemSecondaryAction} from "@mui/material";
import {faUserPlus} from "@fortawesome/pro-light-svg-icons";
import {UserContentMenu} from "@components/layout/dashboard/drawer/content/UserContentMenu";

export const DashboardDrawerContent = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const user = useConnectedUser()


    return <React.Fragment>
        <List subheader={<ListSubheader>Mon espace</ListSubheader>}>
            <ListItem onClick={_ => startTransition(() => {
                navigate("/dashboard")
            })}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faHome}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.home", "Accueil")}/>
            </ListItem>
            <ListItem onClick={_ => navigate("/groups/mine")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faUserGroup}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.prayer", "Prière")}/>
            </ListItem>

            <ListItem onClick={_ => navigate("/groups/add")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faPlusCircle}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.prayer_add", "Créer un groupe")}/>
            </ListItem>

            <UserContentMenu />
        </List>
        <List title="Mon église">

            <DrawerCommunityMembershipMenu/>
        </List>

        {user?.isSuperuser && <Box sx={{
            borderTop: "2px #ddd solid",
            backgroundColor: "#fffceb",
        }}
        ><List
            dense
            subheader={<ListSubheader sx={{backgroundColor: "inherit"}}>Super Admin</ListSubheader>}>

            <ListItemButton onClick={_ => navigate("/su/organizations")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faShield}/>
                </ListItemIcon>
                <ListItemText primary={t("menu.super_admin.organizations", "Organisations")}/>
            </ListItemButton>
        </List></Box>
        }
    </React.Fragment>
}
