import {useOrganizationDetails} from "@organizations/loaders/organizationDetailsLoader";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";
import {Community, Group} from "@graph";
import {useGetGroupDetails, useGroupDetails} from "@profusion.media/profusion-core-group";

export interface GroupJoinInfoProps {
    group: Group
}

export const GroupJoinInfo = (props: GroupJoinInfoProps) => {
    const {group} = props

    return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
        <UserAvatar size={64} avatar={group.avatar?.smallUrl} userName={group.title}/>

        <Typography variant="h6" fontWeight={700} color="secondary">{group.title}</Typography>

        <Typography variant="body1">{group.createdBy.displayName}</Typography>

    </Box>
}