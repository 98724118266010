import {WhiteBox} from "@components/WhiteBox";
import {QRCode} from "react-qrcode-logo";
import {MutableRefObject, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle1, faCircle2} from "@fortawesome/pro-regular-svg-icons";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

export interface QrCodeSharingProps {
    url: string
}

export const QrCodeSharing = (props: QrCodeSharingProps) => {
    const {url} = props
    const ref = useRef<QRCode>(null);

    const onDownload = () => {
        ref.current?.download("png", "qr-code.png")
    }

    return <>

        <WhiteBox>
            <Stack direction="row" spacing={2} alignItems="center">
                <FontAwesomeIcon icon={faCircle1}/>
                <Typography variant="body2">Invitez votre contact à ouvrir son application Profusion </Typography>
            </Stack>
        </WhiteBox>

        <WhiteBox>
            <Stack direction="row" spacing={2} alignItems="center" mt={2}>
                <FontAwesomeIcon icon={faCircle2}/>
                <Typography variant="body2">Demandez-lui de scanner le code ci-dessous à partir de
                    Profusion</Typography>
            </Stack>

            <Box padding={2}>
                <QRCode
                    size={240}
                    ref={ref as MutableRefObject<QRCode>}
                    id="qrcode"
                    value={url}/>
            </Box>
            <Button variant="outlined" onClick={onDownload}>Télécharger ce code QR</Button>

        </WhiteBox>
    </>
}