import {useNavigate} from "react-router-dom";

export const useNavigateDetails = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}`, {
        state: {
            from: window.location.pathname
        }
    })
}

export const useNavigateEdit = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/edit`)
}

export const useNavigateManageContent = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/content/manage`)
}

export const useNavigateInvite = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/invite`)
}

export type GroupTab = "prayer" | "settings" | "subscribers" | "about" | "content" | "community"

export const useNavigateDetailsTab = (hid: string) => {
    const navigate = useNavigate()
    return (tab: GroupTab) => navigate(`/groups/${hid}/${tab}`, {
        replace: true
    })
}
export const useNavigationCreateGroup = () => {
    const navigate = useNavigate()
    return () => navigate("/groups/add")
}

export const useNavigationInvitationList = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/invitations`)
}

export const useNavigationInvitation = () => {
    const navigate = useNavigate()
    return (hid: string) => navigate(`/groups/${hid}/invitations/add`)
}

export const useNavigationBack = () => {
    const navigate = useNavigate()
    return (folder: string) => navigate(`/groups/${folder === 'own' ? 'mine' : 'shared'}`)
}