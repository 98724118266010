import {Box, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useProfusionSession} from "@profusion.media/profusion-core";
import * as React from "react";
import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {defaultUserRegistration, useAccountRegister, UserRegistrationInput} from "@profusion.media/profusion-iam-user";
import {StepIdentity} from "./StepIdentity";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Form} from "@profusion.media/profusion-ui";
import CopyrightLine from "../../components/utils/CopyrightLine";
import Button from "@mui/material/Button";

const theme = createTheme();

const RegisterPage = () => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const session = useProfusionSession()
    const [register, {loading: updating, validationErrors, data: registerReponse}] = useAccountRegister()
    const [accountData, setAccountData] = useState<UserRegistrationInput>(defaultUserRegistration)


    const handleChangeValue = (name: string, value: any) => {
        setAccountData((prev: any) => ({...prev, [name]: value}))
    }

    const handleGoLogin = () => {
        const xt = searchParams.get("xt") ?? false
        const next = searchParams.get("next") ?? false
        navigate("/login?rel=register" + (next ? "&next=" + next : "") + (xt ? "&xt=" + xt : ""))
    }


    const handleComplete = async () => {
        const r = await register({input: {...accountData}})
        const nextUrl = searchParams.get("next") ?? r.data?.token.next

        if (!r?.validationErrors && r.data) {
            await session.refresh(r.data.token.refreshToken)
            if (nextUrl) {
                window.location.replace(nextUrl)
            } else {
                window.location.replace("/dashboard")

            }


        }
    }

    const handleCancel = () => {
        navigate("/login")
    }

    return <ThemeProvider theme={theme}>
        <Container fixed maxWidth="sm">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("register.title", "Créer un compte")}
                </Typography>

                <Typography
                    onClick={handleGoLogin}
                    color="primary"
                    variant="body2" mt={2}>{t("register.login_description", "J'ai déjà un compte")}</Typography>
            </Box>

            <Box sx={{mt: 4}}>
                <Form onSubmit={handleComplete}
                      onCancel={handleCancel}
                      errors={validationErrors}
                      updating={updating}
                >

                    <StepIdentity value={accountData}
                                  onChange={handleChangeValue}/>


                </Form>
            </Box>
            <Stack
                direction="column"
                spacing={2}
                sx={{textAlign: "center", mt: 4}}>

                <Button
                    variant="text"
                    color="primary"
                    onClick={_ => handleGoLogin()}>
                    {t("register.login_button", "Me connecter")}
                </Button>
            </Stack>


            <Box sx={{mt: 6}}>
                <Typography align="center">
                    {t("powered_by_profusion", "Propulsé par profusion.media")}
                </Typography>
                <CopyrightLine/>
            </Box>
        </Container>
    </ThemeProvider>
}

export default RegisterPage