import {UserAvatarProps, UserAvatarPropsDefaults} from "./typings";
import {Avatar} from "@mui/material";
import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons";


function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string, size?: number) {
    const sizes = size ? {width: size, height: size} : {}
    const letter1 = name.split(' ')[0][0].toUpperCase()
    const word2 = name.split(' ')[1]
    const letter2 = (word2 ? word2[0] : '').toUpperCase()
    return {
        sx: {
            backgroundColor: stringToColor(name),
            ...sizes
        },
        children: `${letter1}${letter2}`,
    };
}

export const UserAvatar = (props: UserAvatarProps) => {
    const {size, userName, avatar} = {...UserAvatarPropsDefaults, ...props}
    const sizes = size ? {width: size, height: size} : {}

    if (avatar) {
        return <Avatar src={avatar}
                       alt={userName}
                       sx={sizes}></Avatar>
    } else if (userName) {
        return <Avatar {...stringAvatar(userName, size)} />
    } else {
        return <Avatar src="https://ik.imagekit.io/profusion/Static_files/877592470d87109eeb659aa91e750039.jpg?updatedAt=1684865194773" />
    }
}