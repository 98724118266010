import {Outlet} from "react-router-dom";
import React from "react"
import DashboardLayout from "../components/layout/dashboard/DashboardLayout";
import {GroupsBottomNavigation} from "./components/GroupsBottomNavigation";

export const DashboardApp = () => {

    return <DashboardLayout>
        <Outlet/>
        <GroupsBottomNavigation/>
    </DashboardLayout>
}