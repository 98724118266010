import {CommunityTag} from "@graph";
import React, {ReactNode} from "react";
import {Box, Stack} from "@mui/material";

export interface TagChipProps {
    tag: CommunityTag
    startIcon?: ReactNode
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export interface TagChipGroupProps {
    tags: CommunityTag[]
    startIcon?: ReactNode
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const TagChip = (props: TagChipProps) => {
    const {tag, startIcon, onClick} = props

    const box = <Box bgcolor={tag.color}
                     padding="1px 8px"
                     borderRadius={2}
                     fontSize={12}
                     alignItems="center"
                     display="inline-flex"
                     onClick={onClick}>{tag.name}</Box>

    return startIcon
        ? <Stack direction="row" spacing={1} alignItems="center">
            {startIcon}
            {box}
        </Stack>
        : box
}

export const TagChipGroup = (props: TagChipGroupProps) => {
    const {tags, startIcon, onClick} = props

    return tags.map(t => <TagChip key={t.hid} tag={t} startIcon={startIcon} />)
}