import {WhiteBox} from "@components/WhiteBox";
import {useGroupList} from "@profusion.media/profusion-core-group";
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-light-svg-icons";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";

export const PrayerGroupsCTA = () => {
    const navigate = useNavigate();
    const groups = useGroupList("mine")

    if (!groups.data) return

    function goCreate() {
        navigate("/groups/add")
    }

    function goMine() {
        navigate("/groups/mine")
    }

    if (groups.data.length === 0) {
        return <WhiteBox title="Les groupes de prières" titleVariant="h5">
            <Box mb={2} >En quelques clics, il vous est possible de créer un
            premier groupe, d'inviter des partenaires et de
                partager des requêtes de prière.</Box>
            <Button variant="contained" startIcon={<FontAwesomeIcon  icon={faPlus} />}
                    onClick={goCreate}>Créer un groupe</Button>
        </WhiteBox>
    } else {
        return <WhiteBox title="Les groupes de prières" titleVariant="h5">
            <Box mb={2}>
                En quelques clics, partagez vos requêtes de prières ou invitez vos partenaires
                à rejoindre votre groupe actuel ou en créer un nouveau.
            </Box>
            <Button variant="contained" endIcon={<FontAwesomeIcon  icon={faChevronRight} />}
                    onClick={goMine}>Mes groupes</Button>
        </WhiteBox>
    }
}