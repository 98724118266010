import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import React, {Suspense} from "react";
import {Await} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Community} from "@graph";
import {CommunityJoin} from "@community/screens/JoinCommunity/CommunityJoin";

export const CommunityHome = () => {
    const {community} = useCommunityDetailsLoader("communityDetails")
    const connectedUser = useConnectedUser()

    return <Suspense>
        <Await resolve={community}>
            {({data}) => ["pending", "none"].includes(data.memberStatus) ? <CommunityJoin community={data}/> :
                <CommunityStartPage community={data}/>}
        </Await>
    </Suspense>
}


interface CommunityStartPageProps {
    community: Community
}


export const CommunityStartPage = (props: CommunityStartPageProps) => {
    const {community} = props

    return <WhiteBox title={community.name}>
        {community.childrenCount}
    </WhiteBox>

}