import {CommunityMembership} from "@graph";
import {useNavigate} from "react-router-dom";
import {Stack} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import Typography from "@mui/material/Typography";
import React from "react";

interface DrawerCommunityInactiveMemberMenuProps {
    membership: CommunityMembership;
}

export const DrawerCommunityInactiveMemberMenu = (props: DrawerCommunityInactiveMemberMenuProps) => {
    const {membership} = props
    const navigate = useNavigate()


    const handleGoInvitation = (hid: string) => {
        navigate(`/community/${hid}`)
    }


    return <Stack direction="row"
                  alignItems="center" gap={1}
                  onClick={_ => handleGoInvitation(membership.communityHid)}>

        <FontAwesomeIcon icon={faInfoCircle} fixedWidth color="#ffa520"/>
        <Typography>Voir l'invitation en attente</Typography>
    </Stack>
}