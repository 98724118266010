import {Community, CommunityInviteResponse} from "@graph";
import {useOnSubmit} from "@profusion.media/profusion-ui";
import {useActionData, useNavigation, useRevalidator, useSubmit} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import React, {useEffect} from "react";

export interface CommunityJoinButtonProps {
    community: Community
}

export const CommunityJoinButton = (props: CommunityJoinButtonProps) => {
    const {community} = props;
    const submit = useSubmit()
    const {revalidate} = useRevalidator()
    const data = useActionData() as CommunityInviteResponse

    const navigation = useNavigation()

    const handleSubmit = () => {
        submit({
            communityHid: community.hid,
        }, {
            method: "POST",
            encType: "application/json",
        })
    }

    console.log(data)

    useEffect(() => {
        if (data?.success) {
            revalidate()
        }
    }, [data?.success])


    return <LoadingButton loading={navigation.state == "loading"}
                          startIcon={<FontAwesomeIcon icon={faCheckCircle}/>}
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
    >Rejoindre</LoadingButton>
}