import {useAuthentication} from "@profusion.media/profusion-core";
import {Await, Outlet, useNavigate, useRouteLoaderData} from "react-router-dom";
import {LoadDetailsReturn} from "@groups/Groups/GroupDetails/loaders";
import React, {Suspense} from "react";
import {GroupJoin} from "@groups/Groups/GroupJoin/GroupJoin";

export const GroupAccessChecker = () => {
    const {is_authenticated} = useAuthentication()
    const navigate = useNavigate()
    const {group: groupData} = useRouteLoaderData("groupDetails") as LoadDetailsReturn

    if (is_authenticated) {
        return <Suspense>
            <Await resolve={groupData}>
                {({data}) => data.userRole.state === 'A_1'
                    ? <GroupJoin group={data}/>
                    : <Outlet/>}
            </Await>
        </Suspense>
    } else {
        navigate("public")
    }
}