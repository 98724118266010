import {Group, GROUP_LIST_ALL_QUERY, GROUP_LIST_MINE_QUERY, GROUP_LIST_SHARED_QUERY, GroupOrigin} from "../schema";
import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";

export const loadGroupList = (client: ProfusionClient, what: GroupOrigin) : GraphQueryPromise<Group[]> => {
  const query =
    what === "shared" ? GROUP_LIST_SHARED_QUERY :
      what === "mine" ? GROUP_LIST_MINE_QUERY :
        GROUP_LIST_ALL_QUERY

  return executeQuery<Group[]>(client, query, {
    isPrivate: true
  })
}
