import * as React from 'react';
import {Suspense, useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import {Await} from "react-router-dom";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {Backdrop} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePlus, faUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {Group} from "@graph";
import {useNavigateInvite, useNavigateManageContent} from "../../../hooks/navigation";
import Typography from "@mui/material/Typography";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {Close} from "@mui/icons-material";
import {useNavigateRequestCreate} from "@groups/Prayers/hooks/navigation";
import {faBooks} from "@fortawesome/pro-light-svg-icons";

export interface GroupSpeedDialProps {
    group: Group
}

export const GroupSpeedDial = (props: GroupSpeedDialProps) => {
    const {group} = props
    const edit = useNavigateRequestCreate(group.hid)
    const invite = useNavigateInvite()
    const manage = useNavigateManageContent()
    const [open, setOpen] = useState(false)

    const actions = [
        group.mainFeature === 'prayer'
         ? {icon: <FontAwesomeIcon icon={faFilePlus}/>, name: 'Ajouter une requête', onClick: () => edit()}
         : {icon: <FontAwesomeIcon icon={faBooks}/>, name: 'Gérer les contenus', onClick: () => manage(group.hid)},
        {icon: <FontAwesomeIcon icon={faUserPlus}/>, name: 'Ajouter un participant', onClick: () => invite(group.hid)},
    ];

    if (group.userRole?.isHost || group.userRole?.isCreator || group.userRole?.isCommunityAdmin) {
        return <Suspense>
            <Await resolve={group}>
                {({data: groupData}) => <>
                    <Backdrop open={open}/>
                    <SpeedDial
                        ariaLabel="Gérer le groupe"
                        onOpen={_ => setOpen(true)}
                        onClose={_ => setOpen(false)}
                        sx={{position: 'absolute', bottom: 16, right: 16}}
                        icon={<SpeedDialIcon openIcon={<Close/>}/>}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                FabProps={{
                                    color: "primary"
                                }}
                                tooltipOpen
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={<Typography variant="caption" noWrap>{action.name}</Typography>}
                                onClick={action.onClick}
                            />
                        ))}
                    </SpeedDial>
                </>
                }
            </Await>
        </Suspense>
    } else {
        return
    }

}