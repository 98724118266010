import {ACCEPT_INVITATION_MUTATION, GROUP_LIST_SHARED_QUERY, RESEND_INVITATION_MUTATION} from "../schema";
import {executeMutation, ProfusionClient, useProfusionClient} from "@profusion.media/profusion-core";
import {
  MutationAcceptInvitationArgs,
  MutationResendInvitationArgs,
  SubscriberResendInvitationMutation,
  SubscriberInvitationInput
} from "@profusion.media/graph";

export type ActionInvitationAcceptResponse = {}

export const actionInvitationAccept = (client: ProfusionClient, groupHid: string, invitationHid: string) => {
  return executeMutation<ActionInvitationAcceptResponse, MutationAcceptInvitationArgs>(client,
    ACCEPT_INVITATION_MUTATION,
    {
      group: groupHid,
      invitation: invitationHid
    }, {
      isPrivate: true,
      refetchQueries: [{
        query: GROUP_LIST_SHARED_QUERY,
        context: {
          use_private: true
        }
      },]
    })
}

export const actionInvitationResend = (client: ProfusionClient, invitation: SubscriberInvitationInput) => {
  return executeMutation<SubscriberResendInvitationMutation, MutationResendInvitationArgs>(client,
    RESEND_INVITATION_MUTATION,
    {
      input: invitation
    }, {
      isPrivate: true,
      refetchQueries: [{
        query: GROUP_LIST_SHARED_QUERY,
        context: {
          use_private: true
        }
      }]
    });
}

export const useInvitationResend = () => {
  const profusion = useProfusionClient()
  return (invitation: SubscriberInvitationInput) => {
    return actionInvitationResend(profusion, invitation)
  }
}

export type invitationResendFunction = ReturnType<typeof useInvitationResend>
