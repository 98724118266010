import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {Box, IconButton, Stack, Toolbar, useTheme} from "@mui/material";
import {useDrawerMenu, UserAvatar} from "@profusion.media/profusion-ui";
import Typography from "@mui/material/Typography";
import {faMultiply} from "@fortawesome/pro-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {faPipe} from "@fortawesome/pro-light-svg-icons";

export const DashboardDrawerHeader = () => {
    const user = useConnectedUser()
    const theme = useTheme()
    const {drawerOpen, toggleDrawer, setDrawerOpen} = useDrawerMenu()
    const navigate = useNavigate()

    if (!drawerOpen) {
        return <Toolbar></Toolbar>
    }

    const handleGoAccount = () => {
        toggleDrawer()
        navigate("/account/personal-info/")
    }

    const handleLogout = () => {
        toggleDrawer()
        navigate("/logout")
    }

    return <Box
        position="relative"
        height="96px"
        marginBottom="96px"
        sx={{backgroundColor: theme.palette.primary.main}}>
        <IconButton onClick={toggleDrawer} sx={{position: "absolute", top: 10, right: 10}}>
            <FontAwesomeIcon icon={faMultiply} color="#fff" fixedWidth/>
        </IconButton>
        <Box display="flex"
             flexDirection="column"
             alignItems="center"
             textAlign="center"
             position="absolute"
             bottom={-96 + 9}
             left={0}
             right={0}
        >
            {user && <UserAvatar userName={user.profile?.displayName}
                                 avatar={user.profile?.avatar?.mediumUrl}
                                 size={96}
            />}
            {user && <Typography>
                {user.firstName} {user.lastName}
            </Typography>}
            <Stack direction="row" divider={<FontAwesomeIcon icon={faPipe}/>} spacing={1} mt={1}>

                <Typography variant="caption"
                            color="secondary"
                            onClick={handleGoAccount}
                >
                    Gérer mon compte
                </Typography>
                <Typography variant="caption"
                            color="default"
                            onClick={handleLogout}
                >
                    Déconnexion
                </Typography>
            </Stack>
        </Box>

    </Box>
}