import {executeQuery, GraphQueryPromise, ProfusionClient} from "@profusion.media/profusion-core";
import {Organization} from "@profusion.media/graph";
import {ORGANIZATION_LIST_QUERY} from "../schema/queries/organization_list";
import {defer, LoaderFunctionArgs, useLoaderData, useRouteLoaderData} from "react-router-dom";
import profusion from "../../../config/profusion";

const _loadOrganizationList = (client: ProfusionClient, page: number = 1, nb: number = 20): GraphQueryPromise<Organization[]> => {
    return executeQuery<Organization[]>(client, ORGANIZATION_LIST_QUERY, {
        isPrivate: true,
        variables: {
            page,
            nb
        }
    })
}

const _loadData = (page: string, nb: string) => {
    return {
        organizations: _loadOrganizationList(profusion,
            parseInt(page), parseInt(nb))
    }
}


export const loadOrganizationList = (props: LoaderFunctionArgs<["page", "nb"]>) => {
    return defer(_loadData(props.params?.page ?? "1", props.params?.nb ?? "20"))
}

export const useOrganizationListLoader = (routeId?: string) => {
    if (routeId) {
        return useRouteLoaderData(routeId) as ReturnType<typeof _loadData>
    } else {
        return useLoaderData() as ReturnType<typeof _loadData>
    }
}


export type LoadOrganizationListParams = {
    page: string
    nb: string
}



