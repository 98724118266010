import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {OrganizationListChurches} from "./OrganizationListChurches";
import {Organization} from "@graph";
import {OrganizationListDenominations} from "./OrganizationListDenominations";
import {OrganizationListPartners} from "./OrganizationListPartners";

interface OrganizationListTabsProps {
    organizations: Organization[]
    onSelect: (organization: Organization) => void;
}

export const OrganizationListTabs = (props: OrganizationListTabsProps) => {
    const {organizations, onSelect} = props
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange}>
                        <Tab label="Églises" value="1"/>
                        <Tab label="Dénominations" value="2"/>
                        <Tab label="Partenaires" value="3"/>
                    </TabList>
                </Box>
                <TabPanel value="1"><OrganizationListChurches organizations={organizations}
                                                              onSelect={onSelect}/></TabPanel>
                <TabPanel value="2"><OrganizationListDenominations organizations={organizations}
                                                                   onSelect={onSelect}/></TabPanel>
                <TabPanel value="3"><OrganizationListPartners organizations={organizations}
                                                              onSelect={onSelect}/></TabPanel>

            </TabContext>
        </Box>
    )
}