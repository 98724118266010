import { gql, DocumentNode } from '@profusion.media/profusion-core'
import {PROFILE_FIELDS} from "@profusion.media/profusion-core-profile";
import {CONTENT_FRAGMENT} from "@community/schema/fragments/content";

export const GROUP_FIELDS : DocumentNode = gql`
  ${PROFILE_FIELDS}
  ${CONTENT_FRAGMENT}
  fragment GroupFields on Group {
    hid
    dateCreated
    title
    invitationsCount
    subscribersCount
    welcome
    organizationHid
    organizationLogo {
      largeUrl
      mediumUrl
      smallUrl      
    }
    organizationName
    joinPolicy
    visibility
    language
    mainFeature
    folder
    communityHid
    tags {
      color
      name
      tagOrder
      hid
    }
    userRole {
      isCreator
      isHost
      notificationSettingsHid
      state
      isCommunityAdmin
    }
    avatar {
      largeUrl
      mediumUrl
      smallUrl
    }
    createdBy {
      ...ProfileFields
    }
    attachments {
      ...CONTENT_FRAGMENT
    }
  }
`

export const GROUP_PUBLIC_FIELDS = gql`
  ${PROFILE_FIELDS}
  fragment GroupPublicFields on GroupPublicDetails {
    hid
    title
    language
    avatar {
      smallUrl
      mediumUrl
      largeUrl
    }
    createdBy {
      ...ProfileFields
    }
  }
  `
