import React from 'react';
import "./config/i18n"
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ProfusionProvider} from "@profusion.media/profusion-core";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import profusion from "./config/profusion"
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import AppUrlListener from "./router/AppUrlListener";

/**
 const breakpoints = {
 values: {
 xs: 0,
 sm: 600,
 md: 960,
 lg: 1280,
 xl: 1920,
 },
 };
 */
// Initialisation du thème avec les breakpoints personnalisés
const theme = createTheme({
    //breakpoints,
});

defineCustomElements(window);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<>
        <AppUrlListener/>
        <ProfusionProvider client={profusion}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </ProfusionProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
