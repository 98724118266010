import {Await, Outlet, useLocation} from "react-router-dom";
import React, {Suspense} from "react"
import DashboardLayout from "@components/layout/dashboard/DashboardLayout";
import {CommunityAdminBottomNavigation} from "@community/screens/admin/components/CommunityAdminBottomNav";
import {EnsureInApp} from "@components/EnsureInApp";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Stack} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import {Community} from "@graph";
import {WhiteBox} from "@components/WhiteBox";
import Typography from "@mui/material/Typography";
import {CommunityAdminSpeedDial} from "@community/screens/admin/components/CommunityAdminSpeedDial";

export const CommunityAdmin = () => {
    const {community, stats} = useCommunityDetailsLoader()

    const location = useLocation()
    return <EnsureInApp next={location.pathname}>
        <Suspense fallback={null}>
            <Await resolve={community}>
                {({data}: { data: Community }) => <DashboardLayout title="Communauté">
                    <WhiteBox>
                        <Stack direction="row" alignItems="center" gap={2}>
                            <UserAvatar
                                avatar={data.organization?.avatar?.smallUrl}
                                userName={data.organization?.name ?? data.name}/>
                            <Stack direction="column">
                                <Typography variant="subtitle1" component="div">
                                    {data.organization?.name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </WhiteBox>
                    <Outlet/>
                    <CommunityAdminSpeedDial community={data} />
                    <CommunityAdminBottomNavigation community={data}/>
                </DashboardLayout>}
            </Await>
        </Suspense>
    </EnsureInApp>
}