import {Form, PhoneInput, SubmitButton, TextInput} from "@profusion.media/profusion-ui";
import {MutationCreateOrganizationArgs, MutationUpdateOrganizationArgs} from "@graph";
import React, {useState} from "react";
import {Box, Stack} from "@mui/material";
import {AddressInput} from "@profusion.media/profusion-ui-address";
import {AddressInput as AddressInputType} from "@profusion.media/profusion-core-geo";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {get} from "lodash"
import Typography from "@mui/material/Typography";
import {WhiteBox} from "@components/WhiteBox";

type OrganizationInput = MutationCreateOrganizationArgs["organization"] | MutationUpdateOrganizationArgs["organization"]

export interface OrganizationFormProps<T extends OrganizationInput> {
    organization: T
    onSubmit: (organization: T) => void
    onCancel: () => void
    errors?: ValidationErrors
    loading?: boolean
    type?: string
}

export function OrganizationForm<T extends OrganizationInput>(props: OrganizationFormProps<T>) {
    const {organization: OrganizationOrigin, type: organizationType, onCancel, onSubmit, errors, loading} = props;
    const [organization, setOrganization] = useState<T>(OrganizationOrigin);
    const handleChange = (name: string, value: any) => {
        setOrganization(prevOrganization => ({
            ...prevOrganization,
            [name]: value
        }));
    };
    const showAddress = organizationType === "church"


    const handleSubmit = () => {
        onSubmit(organization);
    };

    return <Form onSubmit={handleSubmit}
                 onCancel={onCancel}
                 errors={errors}
                 loading={loading}
    >
        <Stack spacing={1} marginBottom={4}>
            <WhiteBox title="Identité">
                <Stack direction="column" spacing={2}>
                    <TextInput onValueChange={handleChange}
                               value={organization.name}
                               name="name"
                               label="Nom"
                    />
                    <TextInput onValueChange={handleChange}
                               value={organization.shortName}
                               name="shortName"
                               label="Nom abbrégé"
                    />
                    <TextInput onValueChange={handleChange}
                               type="url"
                               value={organization.website}
                               name="website"
                               label="Site web"
                    />
                </Stack>
            </WhiteBox>
            {showAddress &&
                <WhiteBox title="Adresse">
                    <AddressInput address={organization.address as AddressInputType}
                                  name="address"
                                  autoComplete
                                  onChange={(address: AddressInputType) => handleChange("address", address)}/>

                </WhiteBox>}
            <WhiteBox title="Personne ressource">
                <Stack direction="row" spacing={2} mb={2}>
                    <TextInput onValueChange={handleChange}
                               value={organization.leaderFirstName}
                               name="leaderFirstName"
                               label="Prénom"
                    />
                    <TextInput onValueChange={handleChange}
                               value={organization.leaderLastName}
                               name="leaderLastName"
                               label="Nom"
                    />
                </Stack>
                <Stack direction="column" spacing={2}>

                <TextInput onValueChange={handleChange}
                               type="email"
                               value={organization.email}
                               name="email"
                               label="E-mail de contact"
                    />
                    <PhoneInput value={organization.phoneNumber ?? ""}
                                onValueChange={handleChange}
                                name="phoneNumber"
                                label="Numéro de téléphone"
                    />

                </Stack>
            </WhiteBox>
            <SubmitButton/>
        </Stack>
    </Form>
}