import {GroupInvitationDetails} from "./Groups/GroupInvitation/GroupInvitationDetails"
import {DashboardApp} from "./DashboardApp";
import {groupListRouter} from "./Groups/groupListRouter";
import {prayerGlobalRouter} from "./Prayers/prayerRouter";
import {GroupInvitationAccept} from "./Groups/GroupInvitation/GroupInvitationAccept";
import {DetailsLoaderProps, loadDetails, loadGroup} from "./Groups/GroupDetails/loaders";
import {GroupScreen} from "./Groups/components/groupScreen/GroupScreen";
import {groupSingleRouter} from "./Groups/groupSingleRouter";
import {GroupAccessChecker} from "./Groups/GroupAccessChecker";
import {GroupPublicView} from "./Groups/GroupPublicView";
import {actionGroupJoin} from "@groups/Groups/GroupJoin/action_join";


const GroupsRouter = [
    {
        path: "/groups/:hid/invitation/:ihid",
        element: <GroupInvitationDetails/>
    },
    {
        path: "/groups/:hid/invitation/:ihid/accept",
        element: <GroupInvitationAccept/>,
        id: "groupInvitationAccept",
        loader: (props: DetailsLoaderProps) => loadGroup(props),
    },
    {
        path: "",
        id: "groups",
        element: <DashboardApp/>,
        children: [
            ...groupListRouter,
            ...prayerGlobalRouter
        ]
    },
    {
        path: "/groups/:hid",
        loader: loadDetails,
        id: "groupDetails",
        element: <GroupAccessChecker/>,
        children: [
            {
                path: "",
                element: <GroupScreen/>,
                children: [
                    ...groupSingleRouter
                ]
            },
            {
                path: "join",
                id: "groupJoinAction",
                action: actionGroupJoin,
            }
        ]
    },
    {
        path: "/groups/:hid/public",
        element: <GroupPublicView/>
    },
    {
        path: "/groups/:hid/join",
        element: <GroupPublicView/>
    }
]

export default GroupsRouter