import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {Await, useNavigate} from "react-router-dom";
import React, {Suspense, useState} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {Community} from "@graph";
import {PeopleSearchOption} from "./options/PeopleSearch";
import {ShareLinkOption} from "./options/ShareLink";
import {Box, Tab} from "@mui/material";
import {TabContext, TabList} from "@mui/lab";


type Params = {
    hid: string
    community: string
}

export const CommunityInvitation = () => {
    const {community} = useCommunityDetailsLoader("communityDetailsAdmin")
    const navigate = useNavigate()
    const [isAdmin, setIsAdmin] = useState("0")


    const getUrl = (c: Community) => {
        return `https://app.profusion.media/community/${c.hid}`
    }

    const onClose = () => {
        navigate("../members")
    }

    const handleChangeAdmin = (event: React.SyntheticEvent, v: string) => {
        setIsAdmin(v)
    }


    return <Suspense>
        <Await resolve={community}>
            {({data}) =>
                <FullScreenDialog title="Inviter dans la communauté"
                                  subtitle={data.name}
                                  onClose={onClose}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabContext value={isAdmin}>
                            <TabList onChange={handleChangeAdmin}>
                                <Tab label="Membre" value="0"/>
                                <Tab label="Adminstrateur" value="1"/>
                            </TabList>
                        </TabContext>
                    </Box>
                    <PeopleSearchOption community={data} url={getUrl(data)} isAdmin={isAdmin === '1'} />
                    <ShareLinkOption community={data} url={getUrl(data)} isAdmin={isAdmin === '1'} />
                </FullScreenDialog>
            }
        </Await>

    </Suspense>
}