import {GroupList} from "./GroupList/GroupList";
import {GroupCreate} from "./GroupCreate/GroupCreate";
import {loadHomeData, loadList} from "./GroupList/loaders";
import {Outlet, redirect, RouteObject} from "react-router-dom"
import {actionCreate} from "./components/settings";
import {HomeScreen} from "../HomeScreen/HomeScreen";
import {PendingInvitationsList} from "../HomeScreen/PendingInvitationsList";


export const groupListRouter: RouteObject[] = [
    {
        path: "",
        element: <Outlet/>,
        children: [
            {
                path: "",
                id: "homeScreen",
                element: <HomeScreen/>,
                loader: () => redirect("/dashboard"),
                children: [
                    {
                        path: "invitations",
                        element: <PendingInvitationsList/>
                    }
                ]
            },
            {

                path: "mine",
                element: <GroupList/>,
                id: "groupMine",
                loader: () => loadList("mine"),
                shouldRevalidate: ({currentUrl}) => {
                    return currentUrl.pathname === "/groups/add";
                },
                handle: {
                    "list": "mine"
                }
            },
            {
                path: "shared",
                element: <GroupList/>,
                id: "groupShared",
                loader: () => loadList("shared"),
                handle: {
                    "list": "shared"
                }
            },
            {
                path: "add",
                element: <GroupCreate/>,
                id: "groupCreate",
                action: (props) => actionCreate(props)
            },
        ]
    },

]

