import * as React from 'react';
import {useState} from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import {useNavigate} from "react-router-dom";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {Backdrop} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {Community} from "@graph";
import Typography from "@mui/material/Typography";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import {Close} from "@mui/icons-material";
import {faExternalLink, faUsersClass} from "@fortawesome/pro-regular-svg-icons";

export interface CommunityAdminSpeedDialProps {
    community: Community;
}

export const CommunityAdminSpeedDial = (props: CommunityAdminSpeedDialProps) => {
    const {community} = props
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleInvite = () => {
        navigate(`/community/admin/${community.hid}/invitation`)
    }

    const handleCreateGroup = () => {
        navigate(`/community/admin/${community.hid}/groups/add`)
    }

    const actions = [
        {icon: <FontAwesomeIcon icon={faExternalLink}/>, name: "Lien d'invitation", onClick: () => handleInvite()},
        {
            icon: <FontAwesomeIcon icon={faUsersClass}/>,
            name: 'Créer un groupe',
            onClick: () => handleCreateGroup()
        },

    ];

    if (community.memberStatus === 'active') {
        return <>
            <Backdrop open={open}/>
            <SpeedDial
                ariaLabel="Gérer la communauté"
                onOpen={_ => setOpen(true)}
                onClose={_ => setOpen(false)}
                sx={{position: 'absolute', bottom: 72, right: 16}}
                icon={<SpeedDialIcon openIcon={<Close/>}/>}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        FabProps={{
                            color: "primary"
                        }}
                        tooltipOpen
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={<Typography variant="caption" noWrap>{action.name}</Typography>}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </>
    } else {
        return
    }

}