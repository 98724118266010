/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `BigInt` scalar type represents non-fractional whole numeric values.
   * `BigInt` is not constrained to 32-bit like the `Int` type and thus is a less
   * compatible type.
   */
  BigInt: { input: any; output: any; }
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any; }
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any; }
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: { input: any; output: any; }
};

export type AcceptInvitationMutation = {
  __typename?: 'AcceptInvitationMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  subscriber?: Maybe<Subscriber>;
};

export type AccountAddressUpdate = {
  __typename?: 'AccountAddressUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountAvatarUpdate = {
  __typename?: 'AccountAvatarUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountBirthdateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountBirthdateUpdate = {
  __typename?: 'AccountBirthdateUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountContactInput = {
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type AccountContactUpdate = {
  __typename?: 'AccountContactUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountCreateMutation = {
  __typename?: 'AccountCreateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  token?: Maybe<RegistrationSuccess>;
};

export type AccountLanguageInput = {
  language: Scalars['String']['input'];
};

export type AccountLanguageUpdate = {
  __typename?: 'AccountLanguageUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountNameInput = {
  displayName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type AccountNameUpdate = {
  __typename?: 'AccountNameUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type AccountNotificationTokenRegister = {
  __typename?: 'AccountNotificationTokenRegister';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  user?: Maybe<User>;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  coordinates: Scalars['String']['output'];
  country: Scalars['String']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  province?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  visibility: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  visibility: Scalars['String']['input'];
};

export type ApiApplication = {
  __typename?: 'ApiApplication';
  avatar?: Maybe<Avatar>;
  isActive: Scalars['Boolean']['output'];
  loginUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiConnect = {
  __typename?: 'ApiConnect';
  clientCountry?: Maybe<ClientCountry>;
  token?: Maybe<ApiToken>;
};

export type ApiKeyExchanger = {
  __typename?: 'ApiKeyExchanger';
  privateKey: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  securityOptions?: Maybe<Array<Maybe<ApiKeyExchangerSecurityOption>>>;
};

export type ApiKeyExchangerSecurityOption = {
  __typename?: 'ApiKeyExchangerSecurityOption';
  constraint?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ApiKeyType = Node & {
  __typename?: 'ApiKeyType';
  application?: Maybe<ApiApplication>;
  exchangers?: Maybe<Array<Maybe<ApiKeyExchanger>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  scope?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  secrets: Scalars['String']['output'];
};

export type ApiKeyTypeConnection = {
  __typename?: 'ApiKeyTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApiKeyTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ApiKeyType` and its cursor. */
export type ApiKeyTypeEdge = {
  __typename?: 'ApiKeyTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ApiKeyType>;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  access?: Maybe<Scalars['String']['output']>;
  refresh?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AttachAvatarInput = {
  fileId: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  hid: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  isAuthenticated: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type Avatar = {
  __typename?: 'Avatar';
  hid: Scalars['String']['output'];
  largeUrl: Scalars['String']['output'];
  mediumUrl: Scalars['String']['output'];
  smallUrl: Scalars['String']['output'];
};

export type AvatarCreateInput = {
  fileId: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ClientCountry = {
  __typename?: 'ClientCountry';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Community = {
  __typename?: 'Community';
  childrenCount?: Maybe<Scalars['Int']['output']>;
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  isMain: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  memberStatus?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<CommunityOrganization>;
  parentHid: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
  tags: Array<Maybe<CommunityTag>>;
};

export type CommunityAdminLinkInput = {
  communityHid: Scalars['String']['input'];
};

export type CommunityAdminLinkResponse = {
  __typename?: 'CommunityAdminLinkResponse';
  link: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityAdminToggleInput = {
  communityHid: Scalars['String']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  userHid: Scalars['String']['input'];
};

export type CommunityAdminToggleResponse = {
  __typename?: 'CommunityAdminToggleResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum CommunityCommunityMemberModelStateChoices {
  /** En attente */
  A_1 = 'A_1',
  /** Actif */
  A_10 = 'A_10',
  /** Suspendu */
  A_20 = 'A_20'
}

export type CommunityGroupAddMemberInput = {
  groupHid: Scalars['String']['input'];
  members: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CommunityInviteInput = {
  communityHid: Scalars['String']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  userHid: Scalars['String']['input'];
};

export type CommunityInviteResponse = {
  __typename?: 'CommunityInviteResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityJoinInput = {
  adminHid?: InputMaybe<Scalars['String']['input']>;
  communityHid: Scalars['String']['input'];
};

export type CommunityJoinResponse = {
  __typename?: 'CommunityJoinResponse';
  community?: Maybe<Community>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CommunityMember = {
  __typename?: 'CommunityMember';
  accessPrayer: Scalars['Boolean']['output'];
  dateAdded: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  state?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<CommunityTag>>>;
  user?: Maybe<CommunityMemberUser>;
};

export type CommunityMemberSetAdminInput = {
  isAdmin: Scalars['Boolean']['input'];
  memberHid: Scalars['String']['input'];
};

export type CommunityMemberSetAllowPrayerInput = {
  isAllowed: Scalars['Boolean']['input'];
  memberHid: Scalars['String']['input'];
};

export type CommunityMemberSetTagInput = {
  isRemoved: Scalars['Boolean']['input'];
  memberHid: Scalars['String']['input'];
  tagHid: Scalars['String']['input'];
};

export type CommunityMemberUser = {
  __typename?: 'CommunityMemberUser';
  avatar?: Maybe<Avatar>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type CommunityMembership = {
  __typename?: 'CommunityMembership';
  communityHid: Scalars['String']['output'];
  communityLogo?: Maybe<Avatar>;
  communityName: Scalars['String']['output'];
  dateAdded: Scalars['DateTime']['output'];
  isAdmin: Scalars['Boolean']['output'];
  prayerGroupHid: Scalars['String']['output'];
  state: CommunityCommunityMemberModelStateChoices;
  tags?: Maybe<Array<Maybe<CommunityTag>>>;
};

export type CommunityOrganization = {
  __typename?: 'CommunityOrganization';
  avatar?: Maybe<Avatar>;
  hid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type CommunityStats = {
  __typename?: 'CommunityStats';
  activeMembers?: Maybe<Scalars['Int']['output']>;
  groups?: Maybe<Scalars['Int']['output']>;
  hid?: Maybe<Scalars['String']['output']>;
  members?: Maybe<Scalars['Int']['output']>;
  membersInGroups?: Maybe<Scalars['Int']['output']>;
};

export type CommunityTag = {
  __typename?: 'CommunityTag';
  color: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tagOrder: Scalars['Int']['output'];
};

export type ConnectTokenAuth = {
  __typename?: 'ConnectTokenAuth';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type ConnectTokenSuccess = {
  __typename?: 'ConnectTokenSuccess';
  loginUrl: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type Content = {
  __typename?: 'Content';
  author?: Maybe<ContentAuthorSnippet>;
  contentUrl: Scalars['String']['output'];
  cover?: Maybe<Avatar>;
  dateAdded: Scalars['DateTime']['output'];
  dateUpdated: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  isPublic: Scalars['Boolean']['output'];
  organization?: Maybe<ContentOrganizationSnippet>;
  tags?: Maybe<Array<Maybe<CommunityTag>>>;
  title: Scalars['String']['output'];
};

export type ContentAuthorSnippet = {
  __typename?: 'ContentAuthorSnippet';
  avatar?: Maybe<Avatar>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type ContentOrganizationSnippet = {
  __typename?: 'ContentOrganizationSnippet';
  avatar?: Maybe<Avatar>;
  hid: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  i18nCode?: Maybe<Scalars['String']['output']>;
};

export type DeclineInvitationMutation = {
  __typename?: 'DeclineInvitationMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DetailedInvitation = {
  __typename?: 'DetailedInvitation';
  answeredAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  invitedBy?: Maybe<InvitationSender>;
  isAccepted: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  recipientEmail: Scalars['String']['output'];
  recipientFirstName: Scalars['String']['output'];
  recipientLastName: Scalars['String']['output'];
  recipientPhoneNumber: Scalars['String']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  tryCount: Scalars['Int']['output'];
};

export type Device = {
  __typename?: 'Device';
  hid: Scalars['String']['output'];
  isTrusted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  systemId: Scalars['String']['output'];
  userAgent: Scalars['String']['output'];
};

export type DeviceCheckTrustedResponse = {
  __typename?: 'DeviceCheckTrustedResponse';
  trusted?: Maybe<Scalars['Boolean']['output']>;
};

export type DeviceInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  notificationToken?: InputMaybe<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
  systemId: Scalars['String']['input'];
  userAgent: Scalars['String']['input'];
};

export type DeviceUpdateMutation = {
  __typename?: 'DeviceUpdateMutation';
  device?: Maybe<Device>;
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type ExchangeToken = {
  __typename?: 'ExchangeToken';
  application?: Maybe<ApiApplication>;
  expiresAt: Scalars['DateTime']['output'];
  refreshToken: Scalars['String']['output'];
};

export type ExchangeTokenSuccess = {
  __typename?: 'ExchangeTokenSuccess';
  loginUrl: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type Group = Node & {
  __typename?: 'Group';
  attachments?: Maybe<Array<Maybe<Content>>>;
  avatar?: Maybe<Avatar>;
  communityHid: Scalars['String']['output'];
  createdBy?: Maybe<ProfileIdentity>;
  dateCreated: Scalars['DateTime']['output'];
  folder: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invitationsCount?: Maybe<Scalars['Int']['output']>;
  isPublic: Scalars['Boolean']['output'];
  joinPolicy: GroupGroupModelJoinPolicyChoices;
  language: Scalars['String']['output'];
  mainFeature: Scalars['String']['output'];
  organizationHid?: Maybe<Scalars['String']['output']>;
  organizationLogo?: Maybe<Avatar>;
  organizationName?: Maybe<Scalars['String']['output']>;
  subscribersCount?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Maybe<CommunityTag>>>;
  title: Scalars['String']['output'];
  userRole?: Maybe<GroupUserSettings>;
  visibility: GroupGroupModelVisibilityChoices;
  welcome: Scalars['String']['output'];
};

export type GroupAvatarUpdateMutation = {
  __typename?: 'GroupAvatarUpdateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  group?: Maybe<Group>;
};

export type GroupCreateInput = {
  communityHid?: InputMaybe<Scalars['String']['input']>;
  feature: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  joinPolicy?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  organizationHid?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
  visibility?: InputMaybe<Scalars['Int']['input']>;
  welcome?: InputMaybe<Scalars['String']['input']>;
};

export type GroupCreateMutation = {
  __typename?: 'GroupCreateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  group?: Maybe<Group>;
};

export type GroupDetails = Group | GroupPublicDetails;

/** An enumeration. */
export enum GroupGroupModelJoinPolicyChoices {
  /** Sur approbation */
  Approve = 'APPROVE',
  /** Ceux qui ont le lien */
  Link = 'LINK',
  /** Tout le monde */
  Open = 'OPEN'
}

/** An enumeration. */
export enum GroupGroupModelVisibilityChoices {
  /** Privé */
  Private = 'PRIVATE',
  /** Public */
  Public = 'PUBLIC',
  /** Restreint */
  Restricted = 'RESTRICTED'
}

export type GroupInviteInput = {
  groupHid: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHost?: InputMaybe<Scalars['Boolean']['input']>;
  userHid: Scalars['String']['input'];
};

export type GroupInviteResponse = {
  __typename?: 'GroupInviteResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupJoinInput = {
  groupHid: Scalars['String']['input'];
};

export type GroupLeaveMutation = {
  __typename?: 'GroupLeaveMutation';
  status?: Maybe<Scalars['String']['output']>;
};

export type GroupPrivacyInput = {
  hid: Scalars['String']['input'];
  joinPolicy?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

export type GroupPublicDetails = Node & {
  __typename?: 'GroupPublicDetails';
  avatar?: Maybe<Avatar>;
  createdBy?: Maybe<ProfileIdentity>;
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  mainFeature: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GroupSetContentInput = {
  contentHid: Scalars['String']['input'];
  groupHid: Scalars['String']['input'];
  isRemoved: Scalars['Boolean']['input'];
};

/** An enumeration. */
export enum GroupSubscriberModelStateChoices {
  /** En attente */
  A_1 = 'A_1',
  /** Actif */
  A_10 = 'A_10',
  /** Retiré */
  A_20 = 'A_20'
}

export type GroupUpdateInput = {
  hid: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  joinPolicy?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
  visibility?: InputMaybe<Scalars['Int']['input']>;
  welcome?: InputMaybe<Scalars['String']['input']>;
};

export type GroupUpdateMutation = {
  __typename?: 'GroupUpdateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  group?: Maybe<Group>;
};

export type GroupUserSettings = {
  __typename?: 'GroupUserSettings';
  isCommunityAdmin?: Maybe<Scalars['Boolean']['output']>;
  isCreator: Scalars['Boolean']['output'];
  isHost: Scalars['Boolean']['output'];
  notificationSettingsHid: Scalars['String']['output'];
  state: GroupSubscriberModelStateChoices;
};

export type ImageKitAuthToken = {
  __typename?: 'ImageKitAuthToken';
  expire?: Maybe<Scalars['BigInt']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Invitation = DetailedInvitation | PublicInvitation;

export type InvitationSender = {
  __typename?: 'InvitationSender';
  avatar?: Maybe<Avatar>;
  displayName?: Maybe<Scalars['String']['output']>;
  hid: Scalars['String']['output'];
};

export type JsdGroupFetchMutation = {
  __typename?: 'JsdGroupFetchMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  groups?: Maybe<Array<Maybe<MigrationGroupData>>>;
};

export type MigrationGroupData = {
  __typename?: 'MigrationGroupData';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<AcceptInvitationMutation>;
  accountAddressUpdate?: Maybe<AccountAddressUpdate>;
  accountAvatarUpdate?: Maybe<AccountAvatarUpdate>;
  accountBirthdateUpdate?: Maybe<AccountBirthdateUpdate>;
  accountContactUpdate?: Maybe<AccountContactUpdate>;
  accountLanguageUpdate?: Maybe<AccountLanguageUpdate>;
  accountNameUpdate?: Maybe<AccountNameUpdate>;
  accountNotificationTokenRegister?: Maybe<AccountNotificationTokenRegister>;
  accountRegister?: Maybe<AccountCreateMutation>;
  apiConnect?: Maybe<ApiConnect>;
  communityAdminLink?: Maybe<CommunityAdminLinkResponse>;
  communityAdminToggle?: Maybe<CommunityAdminToggleResponse>;
  communityGroupAddMembers?: Maybe<Array<Maybe<Subscriber>>>;
  communityInvite?: Maybe<CommunityInviteResponse>;
  communityJoin?: Maybe<CommunityJoinResponse>;
  communityMemberSetAdmin?: Maybe<CommunityMember>;
  communityMemberSetAllowPrayer?: Maybe<CommunityMember>;
  communityMemberSetTag?: Maybe<CommunityMember>;
  connectTokenAuth?: Maybe<ConnectTokenAuth>;
  connectTokenCreate?: Maybe<ConnectTokenSuccess>;
  connectTokenUpdate?: Maybe<ReturnToken>;
  createOrganization?: Maybe<OrganizationCreate>;
  declineInvitation?: Maybe<DeclineInvitationMutation>;
  deleteOrganization?: Maybe<OrganizationDeleteMutation>;
  deviceUpdate?: Maybe<DeviceUpdateMutation>;
  exchangeTokenConnect?: Maybe<AuthenticatedUser>;
  exchangeTokenCreate?: Maybe<ExchangeTokenSuccess>;
  groupAvatarUpdate?: Maybe<GroupAvatarUpdateMutation>;
  groupCreate?: Maybe<GroupCreateMutation>;
  groupJoin?: Maybe<Group>;
  groupLeave?: Maybe<GroupLeaveMutation>;
  groupPrivacyUpdate?: Maybe<Group>;
  groupSetContent?: Maybe<Group>;
  groupUpdate?: Maybe<GroupUpdateMutation>;
  groupUserInvite?: Maybe<GroupInviteResponse>;
  jsdGroupFetch?: Maybe<JsdGroupFetchMutation>;
  notificationRead?: Maybe<NotificationReadMutation>;
  /** Obtain JSON Web Token mutation using OTP validation  */
  otpAuth?: Maybe<ObtainJsonWebTokenOtp>;
  otpConsume?: Maybe<OtpConsumeResponse>;
  otpSendCode?: Maybe<OneTimePassword>;
  otpVerifyCode?: Maybe<OtpVerifyResponse>;
  passwordReset?: Maybe<PasswordResetMutation>;
  passwordUpdate?: Maybe<PasswordUpdateMutation>;
  passwordUpdateReset?: Maybe<PasswordUpdateResetMutation>;
  phoneLookup?: Maybe<PhoneNumberLookupData>;
  prayerAddPrayedEvent?: Maybe<PrayerEventPrayedMutation>;
  prayerCommentCreate?: Maybe<PrayerEventCommentMutation>;
  prayerEventCreate?: Maybe<PrayerEventCreateMutation>;
  prayerPinCreate?: Maybe<PrayerPinCreateMutation>;
  prayerRequestCreate?: Maybe<PrayerRequestCreateMutation>;
  prayerRequestUpdate?: Maybe<PrayerRequestUpdateMutation>;
  refreshToken?: Maybe<Refresh>;
  resendInvitation?: Maybe<SubscriberResendInvitationMutation>;
  revokeToken?: Maybe<Revoke>;
  ssoTokenCreate?: Maybe<SsoTokenSuccess>;
  subscriberInvite?: Maybe<SubscriberInvitationMutation>;
  subscriberRemove?: Maybe<SubscriberRemoveMutation>;
  subscriberUpdate?: Maybe<SubscriberUpdateMutation>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateAvatarOrganization?: Maybe<OrganizationAvatarUpdateOutput>;
  updateNotificationSettings?: Maybe<NotificationSettingsMutation>;
  updateOrganization?: Maybe<OrganizationUpdate>;
  updateProfileIdentity?: Maybe<ProfileIdentityUpdate>;
  verifyToken?: Maybe<Verify>;
};


export type MutationAcceptInvitationArgs = {
  group: Scalars['String']['input'];
  invitation: Scalars['String']['input'];
};


export type MutationAccountAddressUpdateArgs = {
  input: AddressInput;
};


export type MutationAccountAvatarUpdateArgs = {
  input: AvatarCreateInput;
};


export type MutationAccountBirthdateUpdateArgs = {
  input: AccountBirthdateInput;
};


export type MutationAccountContactUpdateArgs = {
  input: AccountContactInput;
};


export type MutationAccountLanguageUpdateArgs = {
  input: AccountLanguageInput;
};


export type MutationAccountNameUpdateArgs = {
  input: AccountNameInput;
};


export type MutationAccountNotificationTokenRegisterArgs = {
  input: NotificationTokenInput;
};


export type MutationAccountRegisterArgs = {
  input: UserRegistrationInput;
};


export type MutationApiConnectArgs = {
  key: Scalars['String']['input'];
  secrets: Scalars['String']['input'];
};


export type MutationCommunityAdminLinkArgs = {
  input: CommunityAdminLinkInput;
};


export type MutationCommunityAdminToggleArgs = {
  input: CommunityAdminToggleInput;
};


export type MutationCommunityGroupAddMembersArgs = {
  input: CommunityGroupAddMemberInput;
};


export type MutationCommunityInviteArgs = {
  input: CommunityInviteInput;
};


export type MutationCommunityJoinArgs = {
  input: CommunityJoinInput;
};


export type MutationCommunityMemberSetAdminArgs = {
  input: CommunityMemberSetAdminInput;
};


export type MutationCommunityMemberSetAllowPrayerArgs = {
  input: CommunityMemberSetAllowPrayerInput;
};


export type MutationCommunityMemberSetTagArgs = {
  input: CommunityMemberSetTagInput;
};


export type MutationConnectTokenAuthArgs = {
  exchangeToken: Scalars['String']['input'];
};


export type MutationConnectTokenCreateArgs = {
  nextUrl: Scalars['String']['input'];
};


export type MutationConnectTokenUpdateArgs = {
  exchangeToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationCreateOrganizationArgs = {
  organization: OrganizationCreateInput;
  parent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeclineInvitationArgs = {
  group: Scalars['String']['input'];
  invitation: Scalars['String']['input'];
};


export type MutationDeleteOrganizationArgs = {
  hid: Scalars['String']['input'];
};


export type MutationDeviceUpdateArgs = {
  input: DeviceInput;
};


export type MutationExchangeTokenConnectArgs = {
  apiKey: Scalars['String']['input'];
  apiSecrets: Scalars['String']['input'];
  exchangeToken: Scalars['String']['input'];
  serviceKey: Scalars['String']['input'];
};


export type MutationExchangeTokenCreateArgs = {
  apiKey: Scalars['String']['input'];
  apiSecrets: Scalars['String']['input'];
  serviceKey: Scalars['String']['input'];
};


export type MutationGroupAvatarUpdateArgs = {
  groupHid: Scalars['String']['input'];
  input: AvatarCreateInput;
};


export type MutationGroupCreateArgs = {
  avatar?: InputMaybe<AvatarCreateInput>;
  input: GroupCreateInput;
};


export type MutationGroupJoinArgs = {
  input: GroupJoinInput;
};


export type MutationGroupLeaveArgs = {
  groupHid: Scalars['String']['input'];
};


export type MutationGroupPrivacyUpdateArgs = {
  input: GroupPrivacyInput;
};


export type MutationGroupSetContentArgs = {
  input: GroupSetContentInput;
};


export type MutationGroupUpdateArgs = {
  avatar?: InputMaybe<AvatarCreateInput>;
  input: GroupUpdateInput;
};


export type MutationGroupUserInviteArgs = {
  input: GroupInviteInput;
};


export type MutationJsdGroupFetchArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationNotificationReadArgs = {
  input: NotificationReadInput;
};


export type MutationOtpAuthArgs = {
  phoneNumber: Scalars['String']['input'];
  validationToken: Scalars['String']['input'];
};


export type MutationOtpConsumeArgs = {
  phoneNumber: Scalars['String']['input'];
  validationToken: Scalars['String']['input'];
};


export type MutationOtpSendCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationOtpVerifyCodeArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationPasswordResetArgs = {
  emailOrPhone: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPasswordUpdateArgs = {
  input: PasswordUpdateInput;
};


export type MutationPasswordUpdateResetArgs = {
  input: PasswordUpdateResetInput;
};


export type MutationPhoneLookupArgs = {
  countryCode: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationPrayerAddPrayedEventArgs = {
  input: PrayerEventPrayerCreateInput;
};


export type MutationPrayerCommentCreateArgs = {
  input: PrayerCommentCreateInput;
};


export type MutationPrayerEventCreateArgs = {
  input: PrayerEventCreateInput;
};


export type MutationPrayerPinCreateArgs = {
  input: PrayerPinCreateInput;
};


export type MutationPrayerRequestCreateArgs = {
  input: PrayerRequestCreateInput;
};


export type MutationPrayerRequestUpdateArgs = {
  input: PrayerRequestUpdateInput;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResendInvitationArgs = {
  input: SubscriberInvitationInput;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSsoTokenCreateArgs = {
  nextUrl: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationSubscriberInviteArgs = {
  input: SubscriberInvitationInput;
};


export type MutationSubscriberRemoveArgs = {
  input: SubscriberRemoveInput;
};


export type MutationSubscriberUpdateArgs = {
  input: SubscriberUpdateInput;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String']['input'];
  exchangeToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};


export type MutationUpdateAvatarOrganizationArgs = {
  input: AttachAvatarInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  input: NotificationSettingsInput;
};


export type MutationUpdateOrganizationArgs = {
  organization: OrganizationUpdateInput;
};


export type MutationUpdateProfileIdentityArgs = {
  hid: Scalars['String']['input'];
  input: ProfileIdentityInput;
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  action: Scalars['String']['output'];
  avatar?: Maybe<Avatar>;
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

/** An enumeration. */
export enum NotificationNotificationSettingsModelNotificationFrequencyChoices {
  /** À chaque interaction */
  Asap = 'ASAP',
  /** Une par jour */
  Daily = 'DAILY',
  /** Une par semaine */
  Weekly = 'WEEKLY'
}

export type NotificationReadInput = {
  messageHid: Array<InputMaybe<Scalars['String']['input']>>;
};

export type NotificationReadMutation = {
  __typename?: 'NotificationReadMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  message?: Maybe<NotificationMessage>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  enableNotificationEmail: Scalars['Boolean']['output'];
  enableNotificationPush: Scalars['Boolean']['output'];
  enableNotificationSms: Scalars['Boolean']['output'];
  hid: Scalars['String']['output'];
  notificationFrequency: NotificationNotificationSettingsModelNotificationFrequencyChoices;
};

export type NotificationSettingsInput = {
  enableNotificationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationPush?: InputMaybe<Scalars['Boolean']['input']>;
  enableNotificationSms?: InputMaybe<Scalars['Boolean']['input']>;
  hid: Scalars['String']['input'];
  notificationFrequency?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationSettingsMutation = {
  __typename?: 'NotificationSettingsMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  settings?: Maybe<NotificationSettings>;
};

export type NotificationTokenInput = {
  token: Scalars['String']['input'];
};

export type OtpConsumeResponse = {
  __typename?: 'OTPConsumeResponse';
  consumedAt?: Maybe<Scalars['DateTime']['output']>;
  isConsumed: Scalars['Boolean']['output'];
  toPhoneNumber: Scalars['String']['output'];
};

export type OtpVerifyResponse = {
  __typename?: 'OTPVerifyResponse';
  expiresAt: Scalars['DateTime']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  validationToken: Scalars['String']['output'];
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** Obtain JSON Web Token mutation using OTP validation  */
export type ObtainJsonWebTokenOtp = {
  __typename?: 'ObtainJSONWebTokenOTP';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type OneTimePassword = {
  __typename?: 'OneTimePassword';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  toPhoneNumber: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Address>;
  email: Scalars['String']['output'];
  hid?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  leaderFirstName: Scalars['String']['output'];
  leaderLastName: Scalars['String']['output'];
  mainCommunity?: Maybe<Community>;
  name: Scalars['String']['output'];
  parentHid?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  profile?: Maybe<ProfileIdentity>;
  shortName: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  website: Scalars['String']['output'];
};

export type OrganizationAvatarUpdateOutput = Organization | ValidationErrors;

export type OrganizationCreate = {
  __typename?: 'OrganizationCreate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
};

export type OrganizationCreateInput = {
  address?: InputMaybe<AddressInput>;
  communityHandle?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  leaderFirstName?: InputMaybe<Scalars['String']['input']>;
  leaderLastName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<ProfileIdentityInput>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationDeleteMutation = {
  __typename?: 'OrganizationDeleteMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  reason?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganizationUpdate = {
  __typename?: 'OrganizationUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  organization?: Maybe<Organization>;
};

export type OrganizationUpdateInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  hid: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  leaderFirstName?: InputMaybe<Scalars['String']['input']>;
  leaderLastName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<ProfileIdentityInput>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PasswordResetMutation = {
  __typename?: 'PasswordResetMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PasswordUpdateInput = {
  confirmPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type PasswordUpdateMutation = {
  __typename?: 'PasswordUpdateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PasswordUpdateResetInput = {
  code: Scalars['String']['input'];
  confirmPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type PasswordUpdateResetMutation = {
  __typename?: 'PasswordUpdateResetMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PhoneNumberLookupData = {
  __typename?: 'PhoneNumberLookupData';
  info?: Maybe<Scalars['GenericScalar']['output']>;
};

export type PrayerCommentCreateInput = {
  comment: Scalars['String']['input'];
  emotion?: InputMaybe<Scalars['String']['input']>;
  parentHid?: InputMaybe<Scalars['String']['input']>;
  requestHid: Scalars['String']['input'];
};

export type PrayerEvent = Node & {
  __typename?: 'PrayerEvent';
  comment?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ProfileIdentity>;
  dateCreated: Scalars['DateTime']['output'];
  emotion?: Maybe<Scalars['String']['output']>;
  eventType: PrayerPrayerEventModelEventTypeChoices;
  groupHid: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  requestHid: Scalars['String']['output'];
};

export type PrayerEventCommentMutation = {
  __typename?: 'PrayerEventCommentMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerEvent?: Maybe<PrayerEvent>;
};

export type PrayerEventCreateInput = {
  emotion?: InputMaybe<Scalars['String']['input']>;
  eventType: Scalars['String']['input'];
  parentHid?: InputMaybe<Scalars['String']['input']>;
  requestHid: Scalars['String']['input'];
};

export type PrayerEventCreateMutation = {
  __typename?: 'PrayerEventCreateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerEvent?: Maybe<PrayerEvent>;
};

export type PrayerEventGroup = Node & {
  __typename?: 'PrayerEventGroup';
  count?: Maybe<Scalars['Int']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  dateLastEvent?: Maybe<Scalars['DateTime']['output']>;
  eventGroupType: PrayerPrayerEventGroupModelEventGroupTypeChoices;
  events?: Maybe<Array<Maybe<PrayerEvent>>>;
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type PrayerEventPrayedMutation = {
  __typename?: 'PrayerEventPrayedMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerEvent?: Maybe<PrayerEvent>;
};

export type PrayerEventPrayerCreateInput = {
  requestHid: Scalars['String']['input'];
};

export type PrayerGroupOverview = {
  __typename?: 'PrayerGroupOverview';
  groupHid: Scalars['String']['output'];
  pendingCount: Scalars['Int']['output'];
  prayerCount: Scalars['Int']['output'];
  prayerThisWeek: Scalars['Int']['output'];
  requestCount: Scalars['Int']['output'];
  requestThisWeek: Scalars['Int']['output'];
  subscriberCount: Scalars['Int']['output'];
};

export type PrayerOverview = {
  __typename?: 'PrayerOverview';
  commentCount: Scalars['Int']['output'];
  prayerAvatars?: Maybe<Array<Maybe<ProfileIdentity>>>;
  prayerCount: Scalars['Int']['output'];
  requestHid: Scalars['String']['output'];
};

export type PrayerPin = Node & {
  __typename?: 'PrayerPin';
  color?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ProfileIdentity>;
  dateCreated: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  prayerRequest: PrayerRequest;
};

export type PrayerPinCreateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  requestHid: Scalars['String']['input'];
};

export type PrayerPinCreateMutation = {
  __typename?: 'PrayerPinCreateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerPin?: Maybe<PrayerPin>;
};

/** An enumeration. */
export enum PrayerPrayerEventGroupModelEventGroupTypeChoices {
  /** comment */
  Comment = 'COMMENT',
  /** emotion */
  Emotion = 'EMOTION',
  /** prayer */
  Prayer = 'PRAYER'
}

/** An enumeration. */
export enum PrayerPrayerEventModelEventTypeChoices {
  /** emotion */
  Emotion = 'EMOTION',
  /** prayed */
  Prayed = 'PRAYED',
  /** reply */
  Reply = 'REPLY',
  /** thread */
  Thread = 'THREAD'
}

export type PrayerRequest = Node & {
  __typename?: 'PrayerRequest';
  createdBy?: Maybe<ProfileIdentity>;
  dateCreated: Scalars['DateTime']['output'];
  dateExpiration?: Maybe<Scalars['Date']['output']>;
  groupHid: Scalars['String']['output'];
  groupTitle?: Maybe<Scalars['String']['output']>;
  hid: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  pinColor?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalPrayerCount?: Maybe<Scalars['Int']['output']>;
  userLastPrayerDate?: Maybe<Scalars['String']['output']>;
  userPrayerCount?: Maybe<Scalars['Int']['output']>;
};

export type PrayerRequestCreateInput = {
  dateExpiration?: InputMaybe<Scalars['Date']['input']>;
  groupHid: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrayerRequestCreateMutation = {
  __typename?: 'PrayerRequestCreateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerRequest?: Maybe<PrayerRequest>;
};

export type PrayerRequestUpdateInput = {
  dateExpiration?: InputMaybe<Scalars['Date']['input']>;
  hid: Scalars['String']['input'];
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PrayerRequestUpdateMutation = {
  __typename?: 'PrayerRequestUpdateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  prayerRequest?: Maybe<PrayerRequest>;
};

export type ProfileIdentity = {
  __typename?: 'ProfileIdentity';
  avatar?: Maybe<Avatar>;
  displayName: Scalars['String']['output'];
  hid: Scalars['String']['output'];
  kind: Scalars['String']['output'];
};

export type ProfileIdentityInput = {
  avatar?: InputMaybe<AvatarCreateInput>;
  customName?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
};

export type ProfileIdentityUpdate = {
  __typename?: 'ProfileIdentityUpdate';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  profile?: Maybe<ProfileIdentity>;
};

export type PublicInvitation = {
  __typename?: 'PublicInvitation';
  answeredAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  invitedBy?: Maybe<InvitationSender>;
  isAccepted: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  tryCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  allGroupOverview?: Maybe<Array<Maybe<PrayerGroupOverview>>>;
  allPrayerRequestOverview?: Maybe<Array<Maybe<PrayerOverview>>>;
  apiKeys?: Maybe<ApiKeyTypeConnection>;
  contentList?: Maybe<Array<Maybe<Content>>>;
  deviceCheckTrust?: Maybe<DeviceCheckTrustedResponse>;
  deviceGetAll?: Maybe<Array<Maybe<Device>>>;
  geoAllCountries?: Maybe<Array<Maybe<Country>>>;
  getCommunityChildren?: Maybe<Array<Maybe<Community>>>;
  getCommunityDetails?: Maybe<Community>;
  getCommunityGroups?: Maybe<Array<Maybe<Group>>>;
  getCommunityMembers?: Maybe<Array<Maybe<CommunityMember>>>;
  getCommunityMembersByTag?: Maybe<Array<Maybe<CommunityMember>>>;
  getCommunityMembership?: Maybe<Array<Maybe<CommunityMembership>>>;
  getCommunityOrganization?: Maybe<Organization>;
  getCommunityStats?: Maybe<CommunityStats>;
  getConnectedUser?: Maybe<User>;
  getExchangeToken?: Maybe<ExchangeToken>;
  getGroupMembersAllowed?: Maybe<Array<Maybe<CommunityMember>>>;
  getMyProfile?: Maybe<ProfileIdentity>;
  getOrganization?: Maybe<Organization>;
  getOrganizationChildren?: Maybe<Array<Maybe<Organization>>>;
  getOrganizationCommunities?: Maybe<Array<Maybe<Community>>>;
  getOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getPublicContent?: Maybe<Array<Maybe<Content>>>;
  getRelayModel?: Maybe<RelayIdType>;
  getUser?: Maybe<User>;
  getUserContent?: Maybe<Array<Maybe<Content>>>;
  getUserIdentity?: Maybe<Array<Maybe<User>>>;
  getUsers?: Maybe<UserConnection>;
  groupDetails?: Maybe<GroupDetails>;
  groupInvitationList?: Maybe<Array<Maybe<Subscriber>>>;
  groupListAll?: Maybe<Array<Maybe<Group>>>;
  groupListMine?: Maybe<Array<Maybe<Group>>>;
  groupListShared?: Maybe<Array<Maybe<Group>>>;
  groupOverview?: Maybe<PrayerGroupOverview>;
  groupSubscriberList?: Maybe<Array<Maybe<Subscriber>>>;
  groupSubscriberSettings?: Maybe<NotificationSettings>;
  imagekitAuth?: Maybe<ImageKitAuthToken>;
  invitationDetails?: Maybe<Invitation>;
  notificationMessages?: Maybe<Array<Maybe<NotificationMessage>>>;
  notificationSettings?: Maybe<NotificationSettings>;
  pendingInvitations?: Maybe<Array<Maybe<PublicInvitation>>>;
  prayerEventReplies?: Maybe<Array<Maybe<PrayerEvent>>>;
  prayerPinList?: Maybe<Array<Maybe<PrayerRequest>>>;
  prayerRequestComments?: Maybe<Array<Maybe<PrayerEvent>>>;
  prayerRequestDetails?: Maybe<PrayerRequest>;
  prayerRequestEventGroups?: Maybe<Array<Maybe<PrayerEventGroup>>>;
  prayerRequestEvents?: Maybe<Array<Maybe<PrayerEvent>>>;
  prayerRequestList?: Maybe<Array<Maybe<PrayerRequest>>>;
  prayerRequestOverview?: Maybe<PrayerOverview>;
  prayerRequestPrayed?: Maybe<Array<Maybe<PrayerEvent>>>;
  searchOrganization?: Maybe<Array<Maybe<SearchOrganizationResult>>>;
  searchUsers?: Maybe<Array<Maybe<SearchResult>>>;
};


export type QueryAllPrayerRequestOverviewArgs = {
  groupHid: Scalars['String']['input'];
};


export type QueryApiKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDeviceCheckTrustArgs = {
  systemId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCommunityChildrenArgs = {
  parentHid: Scalars['String']['input'];
};


export type QueryGetCommunityDetailsArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetCommunityGroupsArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetCommunityMembersArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetCommunityMembersByTagArgs = {
  hid: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};


export type QueryGetCommunityOrganizationArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetCommunityStatsArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetExchangeTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryGetGroupMembersAllowedArgs = {
  groupHid: Scalars['String']['input'];
};


export type QueryGetOrganizationArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetOrganizationChildrenArgs = {
  parent: Scalars['String']['input'];
};


export type QueryGetOrganizationCommunitiesArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetOrganizationsArgs = {
  nb?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetRelayModelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserArgs = {
  hid: Scalars['String']['input'];
};


export type QueryGetUserIdentityArgs = {
  hids: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGroupDetailsArgs = {
  group: Scalars['String']['input'];
};


export type QueryGroupInvitationListArgs = {
  group: Scalars['String']['input'];
};


export type QueryGroupOverviewArgs = {
  groupHid: Scalars['String']['input'];
};


export type QueryGroupSubscriberListArgs = {
  group: Scalars['String']['input'];
};


export type QueryGroupSubscriberSettingsArgs = {
  group: Scalars['String']['input'];
};


export type QueryInvitationDetailsArgs = {
  hid: Scalars['String']['input'];
};


export type QueryNotificationSettingsArgs = {
  hid: Scalars['String']['input'];
};


export type QueryPrayerEventRepliesArgs = {
  parentHid: Scalars['String']['input'];
};


export type QueryPrayerRequestCommentsArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QueryPrayerRequestDetailsArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QueryPrayerRequestEventGroupsArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QueryPrayerRequestEventsArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QueryPrayerRequestListArgs = {
  group: Scalars['String']['input'];
};


export type QueryPrayerRequestOverviewArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QueryPrayerRequestPrayedArgs = {
  prayerRequest: Scalars['String']['input'];
};


export type QuerySearchOrganizationArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchUsersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RegistrationSuccess = {
  __typename?: 'RegistrationSuccess';
  accessToken?: Maybe<Scalars['String']['output']>;
  next?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type RelayIdType = {
  __typename?: 'RelayIDType';
  id?: Maybe<Scalars['ID']['output']>;
  model?: Maybe<Scalars['String']['output']>;
};

export type ReturnToken = {
  __typename?: 'ReturnToken';
  nextUrl: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int']['output'];
};

export type SearchOrganizationResult = {
  __typename?: 'SearchOrganizationResult';
  avatar?: Maybe<Avatar>;
  city?: Maybe<Scalars['String']['output']>;
  hid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  avatar?: Maybe<Avatar>;
  city?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  hid: Scalars['String']['output'];
};

export type SsoTokenSuccess = {
  __typename?: 'SsoTokenSuccess';
  returnUrl: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type Subscriber = {
  __typename?: 'Subscriber';
  dateActivated?: Maybe<Scalars['DateTime']['output']>;
  dateCreated: Scalars['DateTime']['output'];
  hid: Scalars['String']['output'];
  invitation?: Maybe<SubscriberInvitation>;
  isCreator: Scalars['Boolean']['output'];
  isHost: Scalars['Boolean']['output'];
  notificationSettingsHid: Scalars['String']['output'];
  state: GroupSubscriberModelStateChoices;
  user?: Maybe<SubscriberUser>;
};

export type SubscriberInvitation = {
  __typename?: 'SubscriberInvitation';
  createdAt: Scalars['DateTime']['output'];
  lastSentAt?: Maybe<Scalars['DateTime']['output']>;
  recipientEmail: Scalars['String']['output'];
  recipientFirstName: Scalars['String']['output'];
  recipientLastName: Scalars['String']['output'];
  recipientPhoneNumber: Scalars['String']['output'];
  tryCount: Scalars['Int']['output'];
};

export type SubscriberInvitationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  group: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['String']['input'];
};

export type SubscriberInvitationMutation = {
  __typename?: 'SubscriberInvitationMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  subscriber?: Maybe<Subscriber>;
};

export type SubscriberRemoveInput = {
  hid: Scalars['String']['input'];
};

export type SubscriberRemoveMutation = {
  __typename?: 'SubscriberRemoveMutation';
  status?: Maybe<Scalars['String']['output']>;
};

export type SubscriberResendInvitationMutation = {
  __typename?: 'SubscriberResendInvitationMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  subscriber?: Maybe<Subscriber>;
};

export type SubscriberUpdateInput = {
  hid: Scalars['String']['input'];
  isHost?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriberUpdateMutation = {
  __typename?: 'SubscriberUpdateMutation';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
  subscriber?: Maybe<Subscriber>;
};

export type SubscriberUser = {
  __typename?: 'SubscriberUser';
  avatar?: Maybe<Avatar>;
  displayName: Scalars['String']['output'];
  hid: Scalars['String']['output'];
};

export type User = Node & {
  __typename?: 'User';
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  emailChecked: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  hid?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Précise si l’utilisateur doit être considéré comme actif. Décochez ceci plutôt que de supprimer le compte. */
  isActive: Scalars['Boolean']['output'];
  /** Précise si l’utilisateur peut se connecter à ce site d'administration. */
  isStaff: Scalars['Boolean']['output'];
  /** Précise que l’utilisateur possède toutes les permissions sans les assigner explicitement. */
  isSuperuser: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<ProfileIdentity>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<User>;
};

export type UserRegistrationInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  code?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar']['output'];
};

export type SearchUsersQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers?: Array<{ __typename?: 'SearchResult', hid: string, displayName?: string | null, city?: string | null, avatar?: { __typename?: 'Avatar', smallUrl: string, mediumUrl: string, largeUrl: string } | null } | null> | null };

export type GetConnectedUserDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConnectedUserDetailsQuery = { __typename?: 'Query', getConnectedUser?: { __typename?: 'User', hid?: string | null, email: string, lastName: string, firstName: string, language: string, isSuperuser: boolean, isActive: boolean, phoneNumber?: string | null, profile?: { __typename?: 'ProfileIdentity', displayName: string, kind: string, avatar?: { __typename?: 'Avatar', smallUrl: string, mediumUrl: string, largeUrl: string } | null } | null } | null };

export type SearchOrganizationQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchOrganizationQuery = { __typename?: 'Query', searchOrganization?: Array<{ __typename?: 'SearchOrganizationResult', hid: string, name: string, shortName: string, city?: string | null, avatar?: { __typename?: 'Avatar', smallUrl: string, mediumUrl: string, largeUrl: string } | null } | null> | null };


export const SearchUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hid"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smallUrl"}},{"kind":"Field","name":{"kind":"Name","value":"mediumUrl"}},{"kind":"Field","name":{"kind":"Name","value":"largeUrl"}}]}}]}}]}}]} as unknown as DocumentNode<SearchUsersQuery, SearchUsersQueryVariables>;
export const GetConnectedUserDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getConnectedUserDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getConnectedUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"language"}},{"kind":"Field","name":{"kind":"Name","value":"isSuperuser"}},{"kind":"Field","name":{"kind":"Name","value":"isActive"}},{"kind":"Field","name":{"kind":"Name","value":"phoneNumber"}},{"kind":"Field","name":{"kind":"Name","value":"profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smallUrl"}},{"kind":"Field","name":{"kind":"Name","value":"mediumUrl"}},{"kind":"Field","name":{"kind":"Name","value":"largeUrl"}}]}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"kind"}}]}}]}}]}}]} as unknown as DocumentNode<GetConnectedUserDetailsQuery, GetConnectedUserDetailsQueryVariables>;
export const SearchOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"city"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"smallUrl"}},{"kind":"Field","name":{"kind":"Name","value":"mediumUrl"}},{"kind":"Field","name":{"kind":"Name","value":"largeUrl"}}]}}]}}]}}]} as unknown as DocumentNode<SearchOrganizationQuery, SearchOrganizationQueryVariables>;