import React, {useEffect, useRef, useState} from "react";
import {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";
import {Box, Button, Slider} from "@mui/material";
import {IKCore} from "imagekitio-react";
import {IKIO_AUTH_URL, IKIO_ENDPOINT, IKIO_PUBLIC} from "../config/imagekit";
import {CustomCropper} from "./CustomCropper";
import {ImageUploaderProps} from "./typings";

export const ImageUploader = (props: ImageUploaderProps) => {
    const {
        shape = "free",
        height = 300,
        width = 300,
        onUploaded,
        onUploadStart,
        onImageReady,
        folder,
        filePrefix = "img",
        buttonRef,
        changeButtonRef
    } = props
    const [image, setImage] = useState<string>(props.image ?? "");
    const [zoom, setZoom] = useState<number>(1)
    const cropperRef = useRef<ReactCropperElement>(null);
    const fileButtonRef = useRef<HTMLInputElement>(null)

    const aspectRatio = (shape === "square" || shape === "rounded") ? 1 : undefined
    let minZoom = 0

    useEffect(() => {
        if (buttonRef && buttonRef.current) {
            buttonRef.current.addEventListener("click", uploadImage)
        }
        if (changeButtonRef && changeButtonRef.current) {
            changeButtonRef.current.addEventListener("click", () => {
                setImage("")
            })
        }
    }, [])

    useEffect(() => {
        setImage(props.image)
    }, [props.image])

    useEffect(() => {
        if (changeButtonRef && changeButtonRef.current) {
            changeButtonRef.current.disabled = !image
        }
    }, [image])

    const onLoadImage = (e: any) => {
        const reader = new FileReader();
        let files;

        e.preventDefault();
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const onImageLoaded = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            minZoom = height / cropperRef.current?.cropper.getImageData().height
            setZoom(minZoom)
            if (onImageReady) onImageReady(true)
        }
    }

    const uploadImage = () => {
        console.log("upload")
        console.log(cropperRef.current)
        if (typeof cropperRef.current?.cropper !== "undefined") {
            cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                if (onUploadStart) onUploadStart()
                const imagekit = new IKCore({
                    publicKey: IKIO_PUBLIC,
                    urlEndpoint: IKIO_ENDPOINT
                });
                fetch(IKIO_AUTH_URL).then(response => response.json()).then(token => {
                    imagekit.upload({
                            file: blob as Blob,
                            fileName: filePrefix + ".jpg",
                            useUniqueFileName: true,
                            folder: folder,
                            signature: token.signature,
                            token: token.token,
                            expire: token.expire
                        }, {
                            publicKey: IKIO_PUBLIC,
                            //authenticationEndpoint: IKIO_AUTH_URL,
                            urlEndpoint: IKIO_ENDPOINT,
                        }
                    ).then(response => {
                        onUploaded({
                            fileId: response.fileId,
                            filePath: response.filePath,
                            url: response.url
                        })
                    })
                })

            })

        }
    }

    return <Box>
        {image ? <Box>
                <Box sx={{width, height}}>
                    <CustomCropper
                        shape={shape}
                        style={{width: "100%", height: "100%"}}
                        ref={cropperRef}
                        zoomTo={zoom}
                        initialAspectRatio={1}
                        aspectRatio={aspectRatio}
                        src={image}
                        viewMode={2}
                        minCropBoxHeight={100}
                        minCropBoxWidth={100}
                        background={true}
                        dragMode="move"
                        responsive={true}
                        autoCropArea={1}
                        ready={(event) => onImageLoaded()}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                        zoom={(z: Cropper.ZoomEvent) => setZoom(z.detail.ratio)}
                    />
                </Box>
                <Slider defaultValue={minZoom}
                        value={zoom}
                        min={minZoom}
                        max={2}
                        step={0.1}
                        onChange={(_, z) => setZoom(z as number)}/>
            </Box>
            : <Box sx={{
                width,
                height,
                borderStyle: "dashed",
                borderColor: "#ccc",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center"
            }}>
                <Button sx={{alignSelf: "center"}}
                        variant="contained"
                        onClick={() => fileButtonRef?.current && fileButtonRef.current.click()}>Choose file</Button>
                <input
                    ref={fileButtonRef}
                    style={{display: "none"}}
                    type="file" onChange={onLoadImage}/>
            </Box>
        }
    </Box>

}

export default ImageUploader;