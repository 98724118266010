import React from 'react';
import {Grid, IconButton, Typography} from '@mui/material';
import {StyledChevronIcon, StyledSettingsListItem} from "./styles";
import {SettingsListItemProps} from "./typings";

export const SettingsListItem: React.FC<SettingsListItemProps> = ({label, value, onEdit, right}) => {
    return (
        <StyledSettingsListItem onClick={onEdit}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{label}:</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography>{value}</Typography>
                </Grid>
                {onEdit && <Grid item xs={2}>
                    {right ? right : <IconButton color="primary" size="small">
                        <StyledChevronIcon/>
                    </IconButton>}
                </Grid>
                }
            </Grid>
        </StyledSettingsListItem>
    );
}
