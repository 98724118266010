import {useLocation, useNavigate} from "react-router-dom";
import {faBookmark, faRectangleHistoryCircleUser, faUserGroup} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {Community} from "@graph";
import {faGears} from "@fortawesome/pro-regular-svg-icons";
import {useTabIndex} from "@groups/Groups/GroupDetails/hooks";

export interface CommunityAdminBottomNavProps {
    community: Community
}

export const CommunityAdminBottomNavigation = (props: CommunityAdminBottomNavProps) => {
    const {community} = props

    const currentTab = parseInt(useTabIndex() || "0")
    const [value, setValue] = React.useState(currentTab ?? 0);
    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation()
    const paths = [
        `/community/admin/${community.hid}/members`,
        `/community/admin/${community.hid}/groups`,
        `/community/admin/${community.hid}/settings`,
    ]

    const navigateTo = (idx: number) => {
        navigate(paths[idx], {
            state: { from: location.pathname}
        })
    }

    return <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                navigateTo(newValue)
            }}
        >
            <BottomNavigationAction label={t("community.menu.members", "Membres")}
                                    icon={<FontAwesomeIcon icon={faUserGroup} fixedWidth/>}/>
            <BottomNavigationAction label={t("community.menu.groups", "Groupes")}
                                    icon={<FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}/>
            <BottomNavigationAction label={t("community.menu.settings", "Paramètres")}
                                    icon={<FontAwesomeIcon icon={faGears} fixedWidth/>}/>

        </BottomNavigation>

    </Paper>
}