import {Organization} from "@graph";
import {ListItem, ListItemAvatar} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import ListItemText from "@mui/material/ListItemText";


interface OrganizationListPartnersProps {
    organizations: Organization[];
    onSelect: (organization: Organization) => void;
}

export const OrganizationListPartners = (props : OrganizationListPartnersProps) => {
    const {organizations, onSelect} = props
    return organizations.filter(o => o.type === "partner").map((organization: Organization) =>
        <ListItem key={organization.hid}
                  onClick={() => onSelect(organization)}>
            <ListItemAvatar>
                <UserAvatar avatar={organization.profile?.avatar?.smallUrl} userName={organization.name}/>
            </ListItemAvatar>
            <ListItemText primary={organization.name}/>
        </ListItem>)

}