import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import React, {Suspense} from "react";
import {Await} from "react-router-dom";
import {WhiteBox} from "@components/WhiteBox";
import {CommunityOrganizationInfo} from "@community/screens/JoinCommunity/CommunityOrganizationInfo";
import Typography from "@mui/material/Typography";
import {Box, Button} from "@mui/material";
import {UserAvatar} from "@profusion.media/profusion-ui";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {faArrowDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CommunityJoinButton} from "@community/screens/JoinCommunity/CommunityJoinButton";
import {Community} from "@graph";

export interface CommunityJoinProps {
    community: Community;
}

export const CommunityJoin = (props: CommunityJoinProps) => {
    const {community} = props
    const connectedUser = useConnectedUser()

    return <WhiteBox justifyContent="center" flexDirection="column" display="flex">
                <Box display="flex"
                     flexDirection="column"
                     alignItems="center"
                     gap={4}
                     paddingY={8}
                >
                    <Typography variant="h5" color="textSecondary" component="div">
                        Vous avez été invité !
                    </Typography>


                    <Box display="flex"
                         flexDirection="column"
                         alignItems="center">
                        <UserAvatar size={64}
                                    userName={connectedUser?.firstName + " " + connectedUser?.lastName}
                                    avatar={connectedUser?.profile?.avatar?.smallUrl}/>
                        <Typography variant="body1">{connectedUser?.firstName}</Typography>
                    </Box>

                    <FontAwesomeIcon icon={faArrowDown} size="2xl"/>

                    <CommunityOrganizationInfo community={community}/>

                    <CommunityJoinButton community={community}/>
                    <Button variant="text" color="inherit">Plus tard</Button>
                </Box>
            </WhiteBox>
}