import {Box, Card, CardActions, CardContent, CardHeader, useTheme} from "@mui/material";
import {PrayerRequest, PrayerRequestOverview} from "@profusion.media/profusion-core-prayer";
import {TimeAgo, UserAvatar} from "@profusion.media/profusion-ui";
import React, {useEffect, useState} from "react";
import {useNavigateRequestDetails} from "../hooks/navigation";
import {PrayerPrayedButton} from "../components/PrayerPrayedButton";
import {useRevalidator} from "react-router-dom";
import {usePrayerRequestOverview} from "../loaders";
import Typography from "@mui/material/Typography";
import {PrayerPinButton} from "../components/PrayerPinButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrayingHands} from "@fortawesome/pro-solid-svg-icons";
import {ClippedContainer} from "@components/ClippedContainer";
import {PrayerCommentButton} from "../components/PrayerCommentButton";

export interface PrayerRequestListItemProps {
    prayerRequest: PrayerRequest
    context?: "list" | "details" | "short" | "bookmark"
}

const defaultProps: Partial<PrayerRequestListItemProps> = {
    context: "list"
}

export const PrayerRequestListItem = (props: PrayerRequestListItemProps) => {
    const {prayerRequest, context} = {...defaultProps, ...props}
    const [overview, setOverview] = useState<PrayerRequestOverview>({
        prayerCount: 0,
        commentCount: 0,
        avatars: [],
        requestHid: prayerRequest.hid
    })
    const overviewData = usePrayerRequestOverview(prayerRequest)
    const navigate = useNavigateRequestDetails(prayerRequest.groupHid)
    const revalidator = useRevalidator()
    const theme = useTheme()

    const isShort = context === "short"
    const isList = context === "list"
    const isBookmark = context === "bookmark"
    const isDetails = context === "details"
    const showDescription = isDetails || true
    const showPrayerStatsButton = isDetails

    useEffect(() => {
        overviewData.then((data: PrayerRequestOverview) => {
            setOverview(data)
        })
    }, [overviewData]);

    const onPrayed = () => {
        if (context === "details") {
            revalidator.revalidate()
        } else {
            revalidator.revalidate()
            setOverview(prev => ({...prev, ["prayerCount"]: prev.prayerCount + 1}))
        }
    }

    const onPinned = () => {
        revalidator.revalidate()
    }


    return <Card elevation={0}
                 sx={{borderStyle: "solid", borderWidth: 1, borderColor: "#ddd"}}
    >
        <CardHeader
            onClick={_ => navigate(prayerRequest.hid)}
            avatar={<UserAvatar avatar={prayerRequest.createdBy?.avatar?.smallUrl}
                                userName={prayerRequest.createdBy?.displayName}/>}
            title={<Typography fontWeight={700}>{prayerRequest.title}</Typography>}
            subheader={isBookmark ? <Typography variant="caption">
                Dans {prayerRequest.groupTitle},&nbsp;
                <TimeAgo container="span" date={prayerRequest.dateCreated}/>
            </Typography> : <TimeAgo container="span" date={prayerRequest.dateCreated}/>
            }
            sx={{paddingBottom: 0}}
        />
        <ClippedContainer
            height={isList ? 192 : undefined}
            sx={{paddingX: "16px"}}
            moreElement={<Typography variant="body2"
                                     sx={{paddingX: "16px"}}
                                     onClick={_ => navigate(prayerRequest.hid)}
            >... lire la suite</Typography>}
        >
            <div dangerouslySetInnerHTML={{__html: prayerRequest.text}}/>
        </ClippedContainer>


        <CardContent sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            {overview.prayerCount > 0 && <Typography variant="body2">
                <Box component="span"
                     sx={{
                         padding: "2px", textAlign: "center", display: "inline-block",
                         width: "16px", height: "16px", lineHeight: "16px", marginRight: 1,
                         borderRadius: "50%", backgroundColor: theme.palette.primary.main
                     }}>
                    <FontAwesomeIcon icon={faPrayingHands} size="xs" color="white" fixedWidth/>
                </Box>
                {overview.prayerCount} prières reçues
            </Typography>}
            {overview.commentCount > 0 && <Typography variant="body2">
                {overview.commentCount} commentaires
            </Typography>}
        </CardContent>


        {!isShort && <CardActions>
            <Box sx={{
                display: "flex", alignItems: "center",
                flex: "1 1",
                justifyContent: "space-between"
            }}>

                <PrayerPrayedButton
                    request={prayerRequest}
                    onComplete={onPrayed}
                    count={overview.prayerCount}
                    showStatsButton={showPrayerStatsButton}
                />

                <PrayerCommentButton request={prayerRequest} />

                <PrayerPinButton
                    request={prayerRequest}
                    onComplete={onPinned}/>
            </Box>
        </CardActions>}

    </Card>
}