import {Group} from "@profusion.media/profusion-core-group";
import {Await, useLoaderData} from "react-router-dom";
import {Grid} from "@mui/material";
import {GroupListTitle} from "./components/GroupListTitle";
import {LoadListReturn} from "./loaders";
import {Suspense} from "react";
import {GroupListSkeleton} from "./components/GroupListSkeleton";
import {GroupListItem} from "./components/GroupListItem";
import {HomeEmptyGroupsMine} from "../../HomeScreen/components/HomeEmptyGroupsMine";
import {HomeEmptyGroupsShared} from "../../HomeScreen/components/HomeEmptyGroupsShared";


export const GroupList = () => {
    const {groups, what} = useLoaderData() as Awaited<LoadListReturn>

    return <div>
        <GroupListTitle what={what}/>

        <Suspense fallback={<GroupListSkeleton count={5}/>}>
            <Await resolve={groups}>
                {({data}) => <Grid id="groupGrid" container columnSpacing={1}

                >
                    {data.length ? data.map((group: Group) => <Grid item xs={12} sm={6} md={4}>
                        <GroupListItem group={group} key={group.hid}/>
                    </Grid>) : what === "mine" ? <HomeEmptyGroupsMine/> : <HomeEmptyGroupsShared/>}
                </Grid>}
            </Await>
        </Suspense>
    </div>
}