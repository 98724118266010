import {CommunityInviteResponse, Group} from "@graph";
import {useActionData, useNavigate, useNavigation, useRevalidator, useSubmit} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import React, {useEffect} from "react";

export interface GroupJoinButtonProps {
    group: Group
}

export const GroupJoinButton = (props: GroupJoinButtonProps) => {
    const {group} = props;
    const submit = useSubmit()
    const {revalidate} = useRevalidator()
    const data = useActionData() as CommunityInviteResponse
    const navigate = useNavigate()

    const navigation = useNavigation()

    const handleSubmit = () => {
        submit({
            groupHid: group.hid,
        }, {
            action: `/groups/${group.hid}/join`,
            navigate: false,
            fetcherKey: `groupJoin${group.hid}`,
            method: "POST",
            encType: "application/json",
        })
    }

    useEffect(() => {
        if (data?.success) {
            revalidate()
            navigate(`/groups/${group.hid}`)
        }
    }, [data?.success])


    return <LoadingButton loading={navigation.state == "loading"}
                          startIcon={<FontAwesomeIcon icon={faCheckCircle}/>}
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
    >Rejoindre</LoadingButton>
}