import React, {useEffect, useState} from 'react'

import {DrawerMenuProps, DrawerMenuPropsDefaults, StyledDrawer, useDrawerMenu} from "@profusion.media/profusion-ui"
import {Box, Drawer, List} from "@mui/material"
import {DashboardDrawerHeader} from "./DashboardDrawerHeader";


export const DashboardDrawer = (props: DrawerMenuProps) => {
    const {children} = {...DrawerMenuPropsDefaults, ...props}
    const {drawerOpen, toggleDrawer, setDrawerOpen} = useDrawerMenu()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Supprimez l'écouteur d'événement lorsque le composant est démonté
        // Cela évite les fuites de mémoire
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []); // Ne vous fiez qu'au montage et au démontage du composant.


    const handleClick = (e: any) => {
        if (windowWidth < 640) {
            setDrawerOpen(false)
        }
    }


    return <Drawer open={drawerOpen} PaperProps={{sx: {width: {xs: "100%", "md" : 400}}}}>
        <DashboardDrawerHeader/>
        <Box component="nav" onClick={handleClick}>
            {children}
        </Box>
    </Drawer>
}