import {useGraphMutation, useGraphQuery} from "@profusion.media/profusion-core";
import {
  ACCEPT_INVITATION_MUTATION,
  GROUP_INVITATION_LIST,
  GROUP_SUBSCRIBER_LIST,
  INVITATION_DETAILS,
  Subscriber,
} from "../schema";
import {Invitation} from "@profusion.media/profusion-iam-user";
import {AcceptInvitationInput} from "../schema/types/invitation_types";

export const useInvitationDetails = (hid: string) => {
  return useGraphQuery<Invitation, { hid: string }>(
    INVITATION_DETAILS, {
      variables: {
        hid
      },
      isPrivate: false,
      skip: !hid
    }
  )
}

export const useAcceptInvitationMutation = () => {
  return useGraphMutation<Subscriber, AcceptInvitationInput>(
    ACCEPT_INVITATION_MUTATION, {
      isPrivate: true,
      refetchQueries: (data: Subscriber, variables: AcceptInvitationInput) => ([{
        query: GROUP_INVITATION_LIST,
        variables: {
          group: variables['group']
        },
        context: {
          use_private: true
        }
      }, {
        query: GROUP_SUBSCRIBER_LIST,
        variables: {
          group: variables['group']
        },
        context: {
          use_private: true
        }
      },
      ])
    });
};
